import { FormWrapper, Loading } from 'components'
import { InputProps } from 'components/UI/Input/Input'
import { useOrganisation } from 'hooks'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import {
  Indexable,
  NewWorkshopObj,
  WorkshopObj,
  WorkshopObjWithUsersAndOrganisation,
} from 'types'
import { formatWorkshop } from 'utils'

/* Types */

type Props = {
  onSuccessfulCreate: (workshop: WorkshopObj) => void
  onSuccessfulDelete?: (id: string) => void
  editing?: boolean
  workshop?: WorkshopObj | null
}

type FormData = Indexable & NewWorkshopObj

/* Functional Component */

const Workshop: React.FC<Props> = ({
  onSuccessfulCreate,
  editing,
  workshop,
  ...rest
}) => {
  const { organisations, modules } = useOrganisation()

  const [minDate, setMinDate] = useState('')
  const [formattedWorkshop, setFormattedWorkshop] =
    useState<WorkshopObj | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  /* Set min date */

  useEffect(() => {
    if (workshop) {
      // const { users, ...rest } = workshop as WorkshopObjWithUsersAndOrganisation
      // setUsers(users)
      const relevantWorkshopInfo = {
        id: workshop.id,
        module_id: workshop.module_id || '',
        session_type: workshop.session_type || '',
        session_1: workshop.session_1 || '',
        s1_link: workshop.s1_link || '',
        session_2: workshop.session_2 || '',
        s2_link: workshop.s2_link || '',
        session_reflection: workshop.session_reflection || '',
        reflection_link: workshop.reflection_link || '',
        ftf_workshop_date: workshop.ftf_workshop_date || '',
        organisation_id: workshop.organisation_id || '',
        workshop_type: workshop.workshop_type,
        delivery: workshop.delivery || '',
      }
      setFormattedWorkshop(
        formatWorkshop(relevantWorkshopInfo, { dateFormat: 'yyyy-MM-dd' }),
      )
    }

    const today = new Date().toISOString().slice(0, 16)
    setMinDate(today)
  }, [workshop])

  /* Handler functions */

  const onSubmit = async (data: FormData) => {
    try {
      if (data.session_1 > data.session_2 || data.session_2 > data.session_3) {
        throw new Error('Session dates are not in chronological order.')
      }

      setIsSubmitting(true)

      const workshopWithUsers = {
        ...data,
        // users,
        organisation_name: data.organisation_id
          ? organisations.find(
              (o) =>
                o.id.toString() === (data.organisation_id as string).toString(),
            )?.name || ''
          : '',
      } as WorkshopObjWithUsersAndOrganisation

      // Add organisation_id of null if we are public and we are updating
      const organisationId =
        data.workshop_type === 'public' ? { organisation_id: null } : {}

      const response = editing
        ? await client.updateWorkshop(data.id, {
            ...workshopWithUsers,
            ...organisationId,
          })
        : await client.postWorkshop(data)

      if ('error' in response) {
        return console.log('TODO: HANDLE ERROR: ', response)
      }

      toast.success(`Workshop ${editing ? 'updated' : 'created'} successfully!`)
      onSuccessfulCreate(editing ? workshopWithUsers : response.workshop)
    } catch (error: any) {
      console.log(
        'src/components/Forms/Workshop::handleSubmit() Error: ',
        error,
      )

      toast.error(
        error.message || 'Something went wrong! Please try again later.',
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  /* Return */

  if ((!!workshop && !formattedWorkshop) || !minDate) return <Loading />

  const fields = [
    {
      id: 'module_id',
      label: 'Name',
      type: 'select',
    },
    {
      id: 'delivery',
      label: 'Delivery Type',
      type: 'radio',
      defaultValue: 'online',
    },
    {
      id: 'workshop_type',
      label: 'Workshop Type',
      type: 'radio',
      defaultValue: 'organisation',
    },
    {
      id: 'organisation_id',
      label: 'Organisation',
      type: 'select',
      hidden: editing && formattedWorkshop?.workshop_type === 'public',
    },
    {
      id: 'session_type',
      label: 'Platform',
      type: 'radio',
    },
    {
      id: 'session_1',
      label: 'Session 1 Date & Time',
      type: 'datetime-local',
      min: editing ? '' : minDate,
    },
    {
      id: 's1_link',
      label: 'Session 1 Link',
      type: 'url',
    },
    {
      id: 'session_2',
      label: 'Session 2 Date & Time',
      type: 'datetime-local',
      min: editing ? '' : minDate,
    },
    {
      id: 's2_link',
      label: 'Session 2 Link',
      type: 'url',
    },
    {
      id: 'ftf_workshop_date',
      label: 'Workshop Date',
      type: 'date',
    },
  ] as InputProps[]

  if (editing) {
    fields.push(
      {
        id: 'session_reflection',
        label: 'Reflection Date & Time',
        type: 'datetime-local',
        min: editing ? '' : minDate,
        formControlProps: {
          isRequired: false,
        },
      },
      {
        id: 'reflection_link',
        label: 'Reflection Link',
        type: 'url',
        formControlProps: {
          isRequired: false,
        },
      },
    )
  }

  return (
    <FormWrapper
      apiDeleteFunction={client.destroyWorkshop}
      onSubmit={onSubmit}
      data={formattedWorkshop}
      editing={editing}
      fields={fields}
      isSubmitting={isSubmitting}
      selectOptions={{ organisation_id: organisations, module_id: modules }}
      radioOptions={{
        delivery: {
          options: [
            { value: 'online', name: 'Online' },
            { value: 'ftf', name: 'Face To Face' },
          ],
          hideOtherFields: [
            { onValue: 'online', field: 'ftf_workshop_date' },
            { onValue: 'ftf', field: 'session_type' },
            { onValue: 'ftf', field: 'session_1' },
            { onValue: 'ftf', field: 's1_link' },
            { onValue: 'ftf', field: 'session_2' },
            { onValue: 'ftf', field: 's2_link' },
          ],
        },
        workshop_type: {
          options: [
            { value: 'organisation', name: 'Organisation' },
            { value: 'public', name: 'Public' },
          ],
          hideOtherFields: [{ onValue: 'public', field: 'organisation_id' }],
        },
        session_type: {
          options: [
            { value: 'zoom', name: 'Zoom' },
            { value: 'ms_teams', name: 'MS Teams' },
          ],
        },
      }}
      optionalFields={['session_reflection', 'reflection_link']}
      {...rest}
    />
  )
}

export default Workshop
