import { api } from 'services'
import {
  FetchResponse,
  NewQuestionObj,
  QuestionObj,
  QuestionQueryOptions,
} from 'types'

const getQuestions = async (
  options: QuestionQueryOptions,
): Promise<FetchResponse<{ questions: QuestionObj[] }>> => {
  return api.get(`/admin/questions`, {}, options)
}

const postQuestion = async (
  question: NewQuestionObj,
): Promise<FetchResponse<{ question: QuestionObj }>> => {
  return api.post(`/admin/questions`, { question })
}

const updateQuestion = async (
  id: string,
  obj: NewQuestionObj,
): Promise<FetchResponse<{ question: QuestionObj }>> => {
  return api.patch(`/admin/questions/${id}`, { question: obj })
}

const destroyQuestion = async (id: string): Promise<FetchResponse<{}>> => {
  return api.delete(`/admin/questions/${id}`)
}

const questions = {
  getQuestions,
  postQuestion,
  updateQuestion,
  destroyQuestion,
}

export default questions
