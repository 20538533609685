import { Flex } from '@chakra-ui/react'
import {
  Accordion,
  Button,
  Modal,
  Text,
} from 'components'
import React, { useEffect, useState } from 'react'
import {
  Indexable,
  UsersWorkshopsStatus,
  WorkshopObj,
  WorkshopObjWithUsersAndOrganisation,
} from 'types'
import { formatDate } from 'utils'

import { getEmailCommunication } from './utils'

export interface onSuccessProp {
  onSuccess: (
    data: WorkshopObj | WorkshopObjWithUsersAndOrganisation | {},
    options?: {
      updateUser?: boolean
      userId?: string
      status?: UsersWorkshopsStatus
    },
  ) => void
}

interface Props extends onSuccessProp {
  workshop: WorkshopObjWithUsersAndOrganisation & Indexable
}

const EmailCommunication: React.FC<Props> = ({ workshop, onSuccess }) => {
  const [showModal, setShowModal] = useState(false)

  const [userNotInvited, setUserNotInvited] = useState<boolean>(false)
  const [userNotReminded, setUserNotReminded] = useState<boolean>(false)

  useEffect(() => {
    const remindedSet = new Set([0, 1])
    setUserNotInvited(workshop.users?.some((user) => user.status === 0))
    setUserNotReminded(
      workshop.users?.some((user) => remindedSet.has(user.status)),
    )
  }, [workshop.users])

  return (
    <>
      <Flex mr={'2'} justifyContent={'center'}>
        <Button onClick={() => setShowModal(true)}>
          {'Workshop Communication'}
        </Button>
      </Flex>

      <Modal
        title={'Email Communication'}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        maxWidth={'xl'}
      >
        <>
          <Accordion
            items={[
              {
                title: 'Show info',
                expandedText: 'Hide info',
                children: (
                  <Text fontSize={'xs'}>
                    {`Listed below are the options for email communication with the current
            workshop. I have made this a fairly "loose" system, as there is nothing
            more frustrating then needing to press a button that has been hidden for
            some reason. When an email is sent, the button will be replaced with a 
            timestamp, and all users will be emailed`}
                  </Text>
                ),
              },
            ]}
          />

          {getEmailCommunication({
            workshop,
            onSuccess,
            userNotInvited,
            userNotReminded,
          }).map((k, i) => {
            const timeSent = workshop?.[k.key]
            if (k.displayCondition === false && !timeSent)
              return <React.Fragment key={`${k.key}${i}`}></React.Fragment>

            return (
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                key={`${k.key}${i}`}
                my={timeSent ? 2 : 0.5}
              >
                <Text mr={6}>{k.title}</Text>
                {timeSent ? (
                  <Text>{`Sent: ${formatDate(
                    timeSent,
                    'HH:MM dd/mm/yyyy',
                  )}`}</Text>
                ) : (
                  k.Component
                )}
              </Flex>
            )
          })}
        </>
      </Modal>
    </>
  )
}

export default EmailCommunication
