import { api } from 'services'
import {
  FetchResponse,
  LoginType,
  UserObj,
  UserWithAddressObj,
  UserWithPasswordSet,
} from 'types'

const login = async ({
  email,
  password,
}: LoginType): Promise<
  FetchResponse<{
    accessToken: string
    user: UserObj
  }>
> => {
  return api.post(
    '/auth/login',
    { email, password },
    { credentials: 'include' },
  )
}

const logout = async () => {
  return api.get('/user/me/logout', { credentials: 'include' })
}

const invite = async (
  id: string,
): Promise<
  FetchResponse<{ user: UserWithPasswordSet & UserWithAddressObj }>
> => {
  return api.get(`/auth/invite/${id}`)
}

const forgotPassword = async (
  email: string,
): Promise<
  FetchResponse<{ user: UserWithPasswordSet & UserWithAddressObj }>
> => {
  return api.post(`/auth/forgot_password`, { email })
}

const getResetPassword = async (
  token: string,
): Promise<FetchResponse<{ userId: string }>> => {
  return api.get(`/auth/reset_password/${token}`)
}

const funcs = { login, logout, invite, forgotPassword, getResetPassword }

export default funcs
