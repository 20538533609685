import { DeviceType, Indexable } from 'types'

export const COUNTRIES = ['australia', 'new zealand']

export const STATES = {
  australia: ['act', 'nsw', 'nt', 'qld', 'sa', 'tas', 'vic', 'wa'],
  'new zealand': [
    'Auckland',
    'Bay of Plenty',
    'Canterbury',
    'Gisborne',
    "Hawke's Bay",
    'Manawatū-Whanganui',
    'Marlborough',
    'Nelson',
    'Northland',
    'Otago',
    'Southland',
    'Taranaki',
    'Tasman',
    'Waikato',
    'Wellington',
    'West Coast',
  ],
} as Indexable

export const STATUSES = {
  USER_CREATED: 0,
  USER_INVITED: 1,
  USER_FOLLOWED_UP: 2,
  PASSWORD_COMPLETE: 3,
  ADDRESS_COMPLETE: 4,
  PRE_WORKSHOP_COMPLETE: 5,
  POST_WORKSHOP_COMPLETE: 6,
}

// Table Headers for Admin Tables

type Headers = {
  [key: string]: Array<{
    title: string
    value: string
    hideFromSearch?: boolean
  }>
}

export const TABLE_HEADERS: Headers = {
  users: [
    { title: 'First Name', value: 'first_name' },
    { title: 'Last Name', value: 'last_name' },
    { title: 'Email', value: 'email' },
    { title: 'Organisation', value: 'organisation_name' },
  ],
  workshops: [
    { title: 'Date', value: 'session_1' },
    { title: 'Name', value: 'module_name' },
    { title: 'Organisation', value: 'organisation_name' },
    { title: 'Pre Reflection', value: 'pre_workshop_available' },
    { title: 'Post Reflection', value: 'post_workshop_available' },
  ],
  user: [
    { title: 'Date', value: 'session_1' },
    { title: 'Name', value: 'module_name' },
    // { title: 'Status', value: 'status' },
  ],
  workshop: [
    { title: 'First Name', value: 'first_name' },
    { title: 'Last Name', value: 'last_name' },
    { title: 'Email', value: 'email' },
    { title: 'Status', value: 'displayStatus' },
    { title: 'Remove', value: 'removeUserFromWorkshop' },
  ],
  modules: [
    { title: 'Name', value: 'name' },
    { title: 'Questions', value: 'questions', hideFromSearch: true },
  ],
  questions: [
    { title: 'Order', value: 'question_order' },
    { title: 'Question', value: 'question' },
  ],
  organisations: [{ title: 'Name', value: 'name' }],
}

export const LARGE_SCREEN_SIZE = 1200

export const GET_DEVICE_TYPE = (width: number): DeviceType => {
  return width <= 375
    ? 'smallMobile'
    : width <= 480
    ? 'largeMobile'
    : width <= 768
    ? 'tablet'
    : width <= LARGE_SCREEN_SIZE
    ? 'laptop'
    : 'largeScreen'
}
