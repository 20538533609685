import { InputProps } from 'components/UI/Input/Input'

const fields = [
  {
    id: 'country',
    label: 'Country',
    type: 'select',
    placeholder: 'Select a country',
    autoComplete: 'country',
  },
  {
    id: 'street_address',
    label: 'Street Address',
    placeholder: '123 George Street',
    autoComplete: 'street-address',
  },
  {
    id: 'suburb',
    label: 'Suburb',
    placeholder: 'Sydney',
    autoComplete: 'address-level2',
  },
  {
    id: 'state',
    label: 'State',
    type: 'select',
    placeholder: 'Select a state',
  },
  {
    id: 'postcode',
    label: 'Post Code',
    placeholder: '2000',
    type: 'number',
    maxLength: 4,
    autoComplete: 'postal-code',
    inputMode: 'numeric',
    pattern: '[0-9]{4}',
  },
] as InputProps[]

export default fields
