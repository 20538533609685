import { api } from 'services'
import {
  FetchResponse,
  NewWorkshopObj,
  QueryOptions,
  WorkshopObj,
  WorkshopObjWithUsersAndOrganisation,
} from 'types'

const getWorkshops = async (
  options: QueryOptions & {
    organisationId?: string
    includePublic?: boolean
  } = {},
): Promise<FetchResponse<{ workshops: WorkshopObj[] }>> => {
  return api.get(`/admin/workshops`, {}, options)
}

const getWorkshop = async (
  id: string,
  options: QueryOptions = {},
): Promise<
  FetchResponse<{ workshop: WorkshopObjWithUsersAndOrganisation }>
> => {
  return api.get(`/admin/workshops/${id}`, {}, options)
}

const postWorkshop = async (
  workshop: NewWorkshopObj & { module_id: string },
): Promise<FetchResponse<{ workshop: WorkshopObj }>> => {
  return api.post('/admin/workshops', { workshop: workshop })
}

const updateWorkshop = async (
  id: string,
  obj: NewWorkshopObj,
): Promise<FetchResponse<{ workshop: WorkshopObj }>> => {
  return api.patch(`/admin/workshops/${id}`, {
    workshop: obj,
  })
}

const destroyWorkshop = async (id: string): Promise<FetchResponse<{}>> => {
  return api.delete(`/admin/workshops/${id}`)
}

const exportWorkshopUsers = async (id: string): Promise<FetchResponse<{}>> => {
  return api.get(`/admin/workshops/${id}/export`)
}

const workshops = {
  getWorkshops,
  getWorkshop,
  postWorkshop,
  updateWorkshop,
  destroyWorkshop,
  exportWorkshopUsers,
}

export default workshops
