import { useLocalStorage } from 'hooks'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { UserObj } from 'types'

// create a new auth context
const AuthContext = createContext({
  isLoading: true,
  login: (_accessToken: string, _user: UserObj) => {},
  logout: () => {},
  user: null as UserObj | null,
  updateUser: (_user: UserObj) => {},
})

// create a provider component for the auth context
const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { removeItem, setItem } = useLocalStorage()

  const [isLoading, setIsLoading] = useState(true) // TODO: IMplement
  const [dataFetched, setDataFetched] = useState(false)
  const [user, setUser] = useState<UserObj | null>(null)

  // Make initial call to see if user is logged in
  const loadUser = useCallback(async () => {
    if (!dataFetched) {
      try {
        const response = await client.getMe()

        // We do not actually want to do anything if this is unauthorized
        if ('error' in response) {
          if (response.status === 401) return

          throw new Error(response.message)
        }

        setUser(response.user)
      } catch (error) {
        toast.error('Something went wrong. Please try again.')
      } finally {
        setIsLoading(false)
        setDataFetched(true)
      }
    }
  }, [dataFetched])

  useEffect(() => {
    loadUser()
  }, [loadUser])

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      // If accessToken is cleared from local storage, log user out
      if (event.key === 'accessToken' && !event.newValue && user) {
        toast.warning('You have been logged out. Please login again.', {
          toastId: 'logout',
        })
        setUser(null)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [user])

  // function to log the user in
  const login = (accessToken: string, user: UserObj) => {
    setItem('accessToken', accessToken)
    setUser(user)
  }

  // function to log the user out
  const logout = async () => {
    await client.logout()

    removeItem('accessToken')
    setUser(null)
  }

  const updateUser = (data: UserObj) => {
    setUser(data)
  }

  // provide the auth context value to its child components
  return (
    <AuthContext.Provider
      value={{ login, logout, user, isLoading, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
