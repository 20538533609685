import { Container, Flex } from '@chakra-ui/react'
import { Button, Card, Heading, Question } from 'components'
import { useAuth } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from 'services'
import {
  ExistingUserWorkshopsResponsesObj,
  QuestionObj,
  ResponseType,
} from 'types'
import { STATUSES } from 'utils'

const Questions: React.FC<ResponseType> = ({ response_type }) => {
  const navigate = useNavigate()
  const { user, updateUser } = useAuth()

  const params = useParams<{ id: string }>()
  const workshopId = params.id as string

  const [questions, setQuestions] = useState<QuestionObj[]>([])
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(1)
  const [userResponses, setUserResponses] = useState<
    ExistingUserWorkshopsResponsesObj[] | undefined
  >([])
  const [questionRanking, setQuestionRanking] = useState<string | undefined>(
    undefined,
  )

  useEffect(() => {
    const loadAssessment = async () => {
      try {
        const response = await client.getUserAssessments(
          workshopId,
          response_type,
        )

        if ('error' in response) {
          throw response.error
        }

        // Search through the ordered `questions` array to find the first
        // question that has not been answered by the user
        if (response.userResponses?.length) {
          const answeredQuestionIds = new Set(
            response.userResponses.map((r) => r.question_id),
          )
          const firstUnansweredQuestion = response.questions.findIndex(
            (question) => !answeredQuestionIds.has(question.id),
          )

          if (firstUnansweredQuestion !== -1) {
            setCurrentQuestionNumber(firstUnansweredQuestion + 1)
          }
        }

        setQuestions(response.questions)
        setUserResponses(response.userResponses)
      } catch (error) {
        console.log('Assessment::loadAssessment - Error: ', error)
        toast.error('Something went wrong. Please try again.')
      }
    }

    loadAssessment()
  }, [user, workshopId, response_type])

  useEffect(() => {
    const question = questions[currentQuestionNumber - 1]
    if (!question) return

    const userResponse = (userResponses || []).find(
      (response) => response.question_id === question.id,
    )
    setQuestionRanking(userResponse?.response)
  }, [currentQuestionNumber, questions, userResponses])

  // Protect the route - if a user navigates back, but they have already
  // completed the assessment, redirect them to the home page
  useEffect(() => {
    // Find the workshop amongst the user's workshops
    const workshop = user?.workshops?.find(
      (w) => w.id.toString() === workshopId,
    )

    // If the workshop does not exist, we are trying to access a workshop we
    // are not enrolled in, so redirect to the home page
    if (!workshop) {
      toast.error(
        'You are not enrolled in this workshop. If you have followed a link, please contact us.',
      )
      return navigate('/')
    }

    if (workshop?.status) {
      const condA =
        workshop.status > STATUSES.ADDRESS_COMPLETE && response_type === 'pre'
      const condB =
        workshop.status > STATUSES.PRE_WORKSHOP_COMPLETE &&
        response_type === 'post'

      if (condA || condB) {
        toast.success('You have already completed this assessment.')
        return navigate('/')
      }
    }
  }, [user, workshopId, navigate, response_type])

  const handleRatingClick = async (rating: string) => {
    const data = {
      workshop_id: workshopId,
      question_id: questions[currentQuestionNumber - 1].id,
      response: rating,
    }
    const response = await client.answerQuestion(
      { ...data, response_type },
      currentQuestionNumber === questions.length,
    )

    if ('error' in response) {
      console.log('TODO: HANDLE ERROR: ')
      return
    }

    // Update the userResponses array with the new response
    setUserResponses((prev) => {
      const prevArr = prev || []
      const existingQuestionIndex = prevArr.findIndex(
        (question) =>
          question.question_id === response.answer.question_id &&
          question.user_id === response.answer.user_id,
      )

      if (existingQuestionIndex !== -1) {
        // Question exists, update the response
        const updatedQuestions = [...prevArr]
        updatedQuestions[existingQuestionIndex].response =
          response.answer.response
        return updatedQuestions
      } else {
        // Question doesn't exist, add it to the end
        return [...prevArr, response.answer]
      }
    })

    // That was our last question, so we're done!
    if (currentQuestionNumber === questions.length) {
      if (user?.workshops) {
        const { uw } = response

        const newUser = {
          ...user,
          workshops: user?.workshops.map((w) =>
            w.id === uw.workshop_id ? { ...w, status: uw.status } : w,
          ),
        }
        updateUser(newUser)
      }

      toast.success('Assessment Complete!')
      return navigate(`/`)
    }
    setCurrentQuestionNumber((prev) => prev + 1)
  }

  const handleForwardClick = () => {
    if (currentQuestionNumber >= questions.length || !questionRanking) return
    setCurrentQuestionNumber((prev) => prev + 1)
  }

  if (!questions.length) return null

  return (
    <Container>
      <Card p={'8'} flex={1} alignItems={'center'}>
        <Container textAlign={'center'}>
          <Heading
            size={'md'}
            mb={'3'}
          >{`Question ${currentQuestionNumber} / ${questions.length}`}</Heading>
          <Question
            question={questions[currentQuestionNumber - 1]}
            questionNumber={currentQuestionNumber}
            handleRatingClick={handleRatingClick}
            alreadySelectedRating={questionRanking}
          />

          <Flex justifyContent={'center'}>
            <Button
              isDisabled={currentQuestionNumber === 1}
              colorScheme={'secondary'}
              onClick={() =>
                setCurrentQuestionNumber((prev) => (prev >= 1 ? prev - 1 : 1))
              }
            >
              {'<'}
            </Button>
            <Button
              isDisabled={
                currentQuestionNumber === questions.length || !questionRanking
              }
              colorScheme={'secondary'}
              onClick={handleForwardClick}
              ml={'3'}
            >
              {'>'}
            </Button>
          </Flex>
        </Container>
      </Card>
    </Container>
  )
}

export default Questions
