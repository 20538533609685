import {
  Card,
  CenteredContainer,
  FormWrapper,
  Heading,
  Loading,
  Text,
} from 'components'
import { useAuth } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from 'services'
import { AddressObj, UserObj } from 'types'
import { capitliseWord, COUNTRIES, STATES } from 'utils'

import fields from './fields'

const Header = () => (
  <>
    <Heading>{'Account'}</Heading>
    <Text type={'bold'} mt={'5'}>
      {'Update any account information here'}
    </Text>
  </>
)

const Account: React.FC = () => {
  const { user, updateUser } = useAuth()
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formattedUser, setFormattedUser] = useState<UserObj & AddressObj>()
  const [dataFetched, setDataFetched] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  const [states, setStates] = useState(STATES.australia)

  useEffect(() => {
    const getAddress = async () => {
      try {
        const response = await client.getUserAddress()

        if ('error' in response) {
          throw response
        }

        // If the user does not have an address, return them to home page
        if (!response.address) navigate('/')

        setFormattedUser({
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email,
          ...response.address,
        })
        setStates(
          STATES[response.address?.country]?.map((state: string) => ({
            id: state,
            name: state.toUpperCase(),
          })),
        )
        setDataFetched(true)
      } catch (error: any) {
        toast.error(error?.message || 'Could not get address')
      }
    }

    if (!dataFetched) getAddress()
  }, [user, dataFetched, navigate])

  //TODO: Fix any
  const handleSubmit = async (data: any) => {
    setIsSubmitting(true)

    try {
      const response = await client.updateMe(data)

      if ('error' in response) {
        throw response
      }

      updateUser({ ...user, ...response.user })

      toast.success('Account updated successfully')
      setIsInvalid(true)
    } catch (error: any) {
      error.handled || toast.error(error?.message || 'Something went wrong!')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCountryChange = (
    country: string,
    formValues: { [key: string]: string },
  ) => {
    const states = STATES[country]

    setStates(
      states.map((state: string) => ({
        id: state,
        name: state.toUpperCase(),
      })),
    )

    const stateValue = states.includes(formValues.state) ? formValues.state : ''

    return {
      ...formValues,
      state: stateValue,
    }
  }

  if (!dataFetched) return <Loading />

  return (
    <CenteredContainer>
      <Card width={'100%'} header={<Header />}>
        <FormWrapper
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          data={formattedUser}
          wasInvalidSubmission={isInvalid}
          setWasInvalidSubmission={setIsInvalid}
          submitButtonProps={{
            width: '100%',
            text: 'Update Account',
          }}
          fields={fields}
          selectOptions={{
            state: states,
            country: COUNTRIES.map((country) => ({
              id: country,
              name: capitliseWord(country),
              hideOtherField: 'state',
              handleChange: handleCountryChange,
            })),
          }}
        />
      </Card>
    </CenteredContainer>
  )
}

export default Account
