/**
 * A file to extend Chakra-UI's theme
 */

import { extendTheme } from '@chakra-ui/react'
import { variables } from 'styles'

const theme = extendTheme({
  fonts: {
    body: `'Montserrat', sans-serif`,
  },

  colors: {
    brand: {
      // 50: '#f2ffde',
      // 100: '#defcb2',
      // 200: '#caf884',
      // 300: '#b5f554',
      // 400: '#a1f226',
      500: variables.colours.primary.base, // Default
      600: variables.colours.primary.dark, // Hover
      700: variables.colours.primary.dark, // Active
      // 800: '#2b4800',
      // 900: '#0b1900',
    },
    secondary: {
      500: variables.colours.secondary.base, // Default
      600: variables.colours.secondary.dark, // Hover
      700: variables.colours.secondary.dark, // Active
    },
    transparent: 'transparent',
  },

  shadows: {
    // Default was: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)
    md: `2px 2px 4px 4px ${variables.colours.shadow}, 2px 1px 2px 0 ${variables.colours.shadow}`,

    // Default was: 0 0 0 3px rgba(66, 153, 225, 0.6)
    outline: `0 0 0 2px ${variables.colours.shadow}`,
  },
})

export default theme
