import { Flex } from '@chakra-ui/react'
import { AdminTable, Heading, UserForm, WorkshopPopup } from 'components'
import { Modal } from 'components'
import { Button } from 'components'
import { useAlert } from 'hooks'
import { useOrganisation } from 'hooks'
import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from 'services'
import { QueryOptions, UserObj, WorkshopObj } from 'types'
import { formatDate, formatWorkshop } from 'utils'

const User: React.FC = () => {
  const params = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { onOpen, onClose } = useAlert()
  const { organisations } = useOrganisation()

  const [user, setUser] = useState<UserObj>()
  const [workshops, setWorkshops] = useState<WorkshopObj[]>([])
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openAddUserToWorkshopModal, setOpenAddUserToWorkshopModal] =
    useState<boolean>(false)

  const setData = useCallback(
    (data: UserObj) => {
      const { workshops, ...rest } = data

      setUser(rest)
      setWorkshops(
        workshops
          ? workshops.map((w) => formatWorkshop(w, { organisations }))
          : [],
      )
    },
    [organisations, setUser, setWorkshops],
  )

  const fetchData = useCallback(
    async (options: QueryOptions) => {
      return await client.getUser(params.id || '', {
        ...options,
        workshops: true,
      })
    },
    [params.id],
  )

  const handleDeleteConfirm = async () => {
    try {
      await client.destroyUser(user?.id || '')
      onClose()
      toast.success('User successfully deleted!')
      navigate('/admin/users')
    } catch (error) {
      console.log('User::handleDeleteConfirm() - Error: ', error)
      toast.error('Something went wrong!')
    }
  }

  const handleUpdate = async (data: UserObj) => {
    setUser(data)
    setOpenEditModal(false)
  }

  const handleWorkshopSelection = async (workshop: WorkshopObj) => {
    const response = await client.addUserToWorkshop(
      user?.id as string,
      workshop?.id as string,
    )
    if ('error' in response) {
      return console.log('TODO: HANDLE ERROR: ', response)
    }
    toast.success('User successfully added to workshop!')
    setWorkshops(
      (workshops || []).concat({
        ...workshop,
        session_1: formatDate(workshop.session_1),
      }),
    )
  }

  const handleRowClick = (index: number) => {
    navigate(`/admin/workshops/${workshops[index].id}`)
  }

  return (
    <>
      <AdminTable
        heading={`${user?.first_name} ${user?.last_name}`}
        SubHeading={
          <Heading fontSize={'md'} textAlign={'center'}>
            {`${user?.organisation_name || ''}`}
          </Heading>
        }
        data={workshops}
        initialDataFetch={fetchData}
        type={'user'}
        setParentData={setData}
        hideSearch={true}
        tableCaption={'Click on a row to view workshop details'}
        onRowClick={handleRowClick}
      />

      <Flex mt={'2'} justifyContent={'flex-end'}>
        <Button
          onClick={() => onOpen(handleDeleteConfirm)}
          mr={'2'}
          colorScheme={'red'}
        >
          {'Delete User'}
        </Button>
        <Button
          onClick={() => setOpenEditModal(true)}
          mr={'2'}
          colorScheme={'blue'}
        >
          {'Update User'}
        </Button>
        <Button
          onClick={() => setOpenAddUserToWorkshopModal(true)}
          mr={'2'}
          colorScheme={'green'}
        >
          {'Add User to Workshop'}
        </Button>
      </Flex>

      <Modal
        title={'Update User'}
        isOpen={openEditModal}
        onClose={() => setOpenEditModal(false)}
        hideButtons={true}
      >
        <UserForm
          onSuccessfulCreate={handleUpdate}
          onSuccessfulDelete={handleDeleteConfirm}
          user={user}
          editing={true}
        />
      </Modal>

      {user ? (
        <Modal
          title={''}
          isOpen={openAddUserToWorkshopModal}
          onClose={() => setOpenAddUserToWorkshopModal(false)}
          hideButtons={true}
          modalSize={'6xl'}
        >
          <WorkshopPopup
            user={user}
            organisationId={user.organisation_id as string}
            handleWorkshopSelection={handleWorkshopSelection}
            currentWorkshops={new Set((workshops || []).map((w) => w.id))}
          />
        </Modal>
      ) : null}
    </>
  )
}

export default User
