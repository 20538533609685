import { Alert } from 'components'
import React, { createContext, useState } from 'react'

interface AlertContextType {
  isOpen: boolean
  onClose: () => void
  onOpen: (func: () => void) => void
  onConfirm: null | (() => void)
}

const AlertContext = createContext<AlertContextType>({
  isOpen: false,
  onClose: () => {},
  onOpen: () => {},
  onConfirm: null,
})

interface Props {
  children: React.ReactNode
}

const AlertProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [onConfirm, setOnConfirm] = useState<null | (() => {})>(null)

  const onClose = () => {
    setOnConfirm(null)
    setIsOpen(false)
  }

  const onOpen = (func: () => void) => {
    setIsOpen(true)
    setOnConfirm(() => func)
  }

  return (
    <AlertContext.Provider value={{ isOpen, onClose, onOpen, onConfirm }}>
      {children}
      <Alert isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} />
    </AlertContext.Provider>
  )
}

export { AlertContext, AlertProvider }
