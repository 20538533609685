import { Flex, useDisclosure } from '@chakra-ui/react'
import { AdminTable, UserForm } from 'components'
import { UserImport } from 'components'
import { Modal } from 'components'
import { Button } from 'components'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { client } from 'services'
import { ImportUsersType, UserObj } from 'types'

const Users: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  const [users, setUsers] = useState<UserObj[]>([])
  const [openUserImport, setOpenUserImport] = useState<boolean>(false)

  const onSuccessfulImport = (data: ImportUsersType['created']) => {
    const userArr = users || []

    setUsers(
      data.reduce(
        (acc, user) => {
          const index = userArr.findIndex((obj) => obj.id === user.id)
          if (index >= 0) {
            acc[index] = user
          } else {
            acc.push(user)
          }
          return acc
        },
        [...userArr],
      ),
    )
  }

  const onSuccessfulCreate = (data: UserObj) => {
    setUsers([...(users || []), data])
    onClose()
  }

  const onSuccessfulDelete = (id: string) => {
    const filteredUsers = users && users.filter((user) => user.id !== id)
    setUsers([...(filteredUsers || [])])
    onClose()
  }

  const handleRowClick = (index: number) => {
    navigate(`/admin/users/${(users || [])[index].id}`)
  }

  return (
    <>
      <AdminTable
        heading={'Users'}
        data={users}
        initialDataFetch={client.getUsers}
        type={'users'}
        onRowClick={handleRowClick}
        setParentData={setUsers}
      />

      <Flex mt={'2'} justifyContent={'flex-end'}>
        <Button onClick={onOpen} mr={'2'} colorScheme={'green'}>
          {'Create User'}
        </Button>
        <Button
          onClick={() => setOpenUserImport(true)}
          mr={'2'}
          colorScheme={'blue'}
        >
          {'Import Users'}
        </Button>
      </Flex>

      <UserImport
        isOpen={openUserImport}
        onClose={() => setOpenUserImport(false)}
        onSuccess={onSuccessfulImport}
      />

      <Modal
        title={'Create New User'}
        isOpen={isOpen}
        onClose={onClose}
        hideButtons={true}
      >
        <UserForm
          onSuccessfulCreate={onSuccessfulCreate}
          onSuccessfulDelete={onSuccessfulDelete}
        />
      </Modal>
    </>
  )
}

export default Users
