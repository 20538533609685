import { Heading } from 'components'
import React from 'react'
import { Indexable, WorkshopObjWithUsersAndOrganisation } from 'types'
import { formatDate } from 'utils'

interface Props {
  workshop: WorkshopObjWithUsersAndOrganisation & Indexable
}

const SubHeading: React.FC<Props> = ({ workshop }) => {
  return (
    <>
      <Heading mb={'2'} fontSize={'md'} textAlign={'center'}>
        {`${workshop?.organisation_name || ''}`}
      </Heading>
      {workshop.ftf_workshop_date ? (
        <Heading
          fontSize={'sm'}
          textAlign={'center'}
        >{`Workshop Date - ${formatDate(workshop.ftf_workshop_date)}`}</Heading>
      ) : (
        [
          { key: 'session_1', title: 'Session 1' },
          { key: 'session_2', title: 'Session 2' },
          { key: 'session_reflection', title: 'Reflection' },
        ].map((k) =>
          workshop?.[k.key] ? (
            <Heading key={k.key} fontSize={'sm'} textAlign={'center'}>{`${
              k.title
            } - ${formatDate(workshop?.[k.key] || '')}`}</Heading>
          ) : null,
        )
      )}
    </>
  )
}

export default SubHeading
