import {
  FormControl,
  FormLabel,
  Radio as ChakraRadio,
  RadioGroup,
  RadioGroupProps,
  Stack,
} from '@chakra-ui/react'
import { Text } from 'components'
import { RadioOptions } from 'types'

interface Props extends RadioGroupProps, RadioOptions {
  label: string
}

const Radio: React.FC<Props> = ({ id, label, options, ...rest }) => {
  return (
    <FormControl isRequired>
      <RadioGroup
        width={'100%'}
        id={id}
        name={id}
        defaultValue={options[0].value}
        {...rest}
      >
        <FormLabel htmlFor={id}>
          <Text type={'bold'} display={'inline'}>
            {label}
          </Text>
        </FormLabel>
        <Stack spacing={5} direction={'row'}>
          {options.map((option) => (
            <ChakraRadio
              key={option.value}
              value={option.value}
              colorScheme={'secondary'}
            >
              {option.name}
            </ChakraRadio>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}

export default Radio
