import {
  Card,
  CenteredContainer,
  FormWrapper,
  Link,
  LogoHeader,
  Text,
} from 'components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from 'services'

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)

      const response = await client.forgotPassword(data.email)

      if ('error' in response) {
        throw response
      }

      toast.dismiss('forgotPasswordError')

      toast.success('You have been sent a link to reset your email!', {
        className: 'forgotPassword__success',
      })
      navigate('/')
    } catch (error: any) {
      error.handled ||
        toast.error(
          error.message ||
            'User does not exist. Please check your email and try again.',
          {
            toastId: 'forgotPasswordError',
            className: 'forgotPassword__error',
          },
        )
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <CenteredContainer>
      <Card
        header={<LogoHeader />}
        footer={<Link to={'/'} text={'Back to login'} />}
      >
        <Text mb={'4'}>
          {`Please enter the email address associated with your account
           and we will send you an email with a link to reset your password.`}
        </Text>
        <FormWrapper
          onSubmit={handleSubmit}
          submitButtonProps={{
            text: isSubmitting ? 'Sending ... ' : 'Continue',
            width: '100%',
          }}
          isSubmitting={isSubmitting}
          fields={[
            {
              id: 'email',
              label: 'Email',
              type: 'email',
              placeholder: 'your_email@address.com',
            },
          ]}
        />
      </Card>
    </CenteredContainer>
  )
}

export default ForgotPassword
