import { ExternalLink, Text } from 'components'
import { WorkshopObj } from 'types'

interface Props {
  workshop: WorkshopObj
}

const PostWorkshopCompleted: React.FC<Props> = ({ workshop }) => {
  return (
    <>
      <Text>
        {`That concludes your participation in the ${workshop.module_name} module of the Being Leaders Program.`}{' '}
      </Text>
      <Text type={'bold'}>
        {'Interested in continuing your leadership development?'}
      </Text>
      <Text display={'inline'}>
        {'Check out our podcast'}
        {' - '}
      </Text>
      <ExternalLink
        display={'inline'}
        href={process.env.REACT_APP_BL_SPOTIFY as string}
      >
        {'Overworked to Incredibly Impactful'}
      </ExternalLink>
    </>
  )
}

export default PostWorkshopCompleted
