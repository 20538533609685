import { SendInvite, SendWorkshopEmail } from 'components'
import { onSuccessProp } from 'components/Admin/Workshop/EmailCommunication'
import { Indexable, WorkshopObjWithUsersAndOrganisation } from 'types'

export const getEmailCommunication = ({
  workshop,
  onSuccess,
  userNotInvited,
  userNotReminded,
}: onSuccessProp & {
  workshop: WorkshopObjWithUsersAndOrganisation & Indexable
  userNotInvited: boolean
  userNotReminded: boolean
}) => {
  const EMAIL_COMMUNICATION_OBJ = {
    onSuccess: onSuccess,
    workshopId: workshop.id,
    delivery: workshop.delivery,
  }
  const REFLECTION_DATA = {
    reflection_link: workshop.reflection_link,
    session_reflection: workshop.session_reflection,
  }

  const email_communcation = [
    {
      key: 'initial_invite',
      title: 'Welcome Email',
      Component: <SendInvite {...EMAIL_COMMUNICATION_OBJ} />,
      // Can only send invite, if at least one user can receive it
      displayCondition: !!userNotInvited,
    },
    {
      key: 'follow_up',
      title: 'Account Activation Reminder',
      Component: <SendInvite type={'reminder'} {...EMAIL_COMMUNICATION_OBJ} />,
      displayCondition:
        !!workshop?.initial_invite &&
        !workshop?.pre_workshop_available &&
        !!userNotReminded,
    },
    {
      key: 'pre_workshop',
      title: 'Pre Program Self Reflection',
      Component: (
        <SendWorkshopEmail {...EMAIL_COMMUNICATION_OBJ} type={'pre_workshop'} />
      ),
      displayCondition: !workshop?.post_workshop_available,
    },
    {
      key: 'understand_role',
      title: 'Understanding Leader of Leaders Role',
      Component: (
        <SendWorkshopEmail
          {...EMAIL_COMMUNICATION_OBJ}
          type={'understand_role'}
        />
      ),
    },
    {
      key: 's1_reminder',
      title: 'Session 1 Reminder',
      Component: (
        <SendWorkshopEmail {...EMAIL_COMMUNICATION_OBJ} type={'s1_reminder'} />
      ),
    },
    {
      key: 's1_summary',
      title: 'Session 1 Thank You',
      Component: (
        <SendWorkshopEmail {...EMAIL_COMMUNICATION_OBJ} type={'s1_summary'} />
      ),
    },
    {
      key: 's2_reminder',
      title: 'Session 2 Reminder',
      Component: (
        <SendWorkshopEmail {...EMAIL_COMMUNICATION_OBJ} type={'s2_reminder'} />
      ),
    },
    {
      key: 's2_summary',
      title: 'Share your experience',
      Component: (
        <SendWorkshopEmail
          {...EMAIL_COMMUNICATION_OBJ}
          {...REFLECTION_DATA}
          type={'s2_summary'}
        />
      ),
    },
    {
      key: 'reflection',
      title: 'Reflection Session Reminder',
      Component: (
        <SendWorkshopEmail
          {...EMAIL_COMMUNICATION_OBJ}
          {...REFLECTION_DATA}
          type={'reflection'}
        />
      ),
    },
    {
      key: 'reflection_reminder',
      title: 'Reflection Session Additional Reminder',
      Component: (
        <SendWorkshopEmail
          {...EMAIL_COMMUNICATION_OBJ}
          {...REFLECTION_DATA}
          type={'reflection_reminder'}
        />
      ),
    },
    {
      key: 'post_workshop',
      title: 'Post Program Self Reflection',
      Component: (
        <SendWorkshopEmail
          {...EMAIL_COMMUNICATION_OBJ}
          {...REFLECTION_DATA}
          type={'post_workshop'}
        />
      ),
      displayCondition: !!workshop?.pre_workshop_available,
    },
  ]

  // Remove emails that are not included in FTF
  if (workshop.delivery === 'ftf') {
    return email_communcation.filter(
      (e) => e.key !== 's1_summary' && e.key !== 's2_reminder',
    )
  }

  return email_communcation
}
