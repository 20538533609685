/**
 * This page does not serve much purpose, in fact we could just go to
 * <Assessment type={'pre'} /> directly from AppRouter, but we will keep
 * it this way, in case we need to add unique features for pre assessment
 * in the future
 */

import { UserQuestions } from 'components'
import React from 'react'

const PreWorkshopQuestions: React.FC = () => {
  return <UserQuestions response_type={'pre'} />
}

export default PreWorkshopQuestions
