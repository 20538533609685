import { Link, Text } from 'components'
import { WorkshopObj } from 'types'
import { formatDate } from 'utils'

interface Props {
  workshop: WorkshopObj
}

const PreWorkshopAvailableNotComplete: React.FC<Props> = ({ workshop }) => {
  return (
    <>
      <Text
        display={'inline'}
      >{`Session 1 of the ${workshop.module_name} module will take place on `}</Text>
      <Text type={'bold'} display={'inline'}>
        {' '}
        {`${formatDate(workshop.session_1)}`}
      </Text>
      <Text display={'inline'} pb={4}>
        {'. To help you focus your development experience during the session,'}{' '}
        {'please complete the below self reflection before then:'}
      </Text>
      <Link
        text={'Pre Program Self Reflection'}
        to={`/user/workshop/${workshop.id}/pre`}
      />
    </>
  )
}

export default PreWorkshopAvailableNotComplete
