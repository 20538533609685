import { Password } from 'components'
import { useAuth } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from 'services'
import { UserObj } from 'types'

const User: React.FC = () => {
  const { user: contextUser } = useAuth()
  const navigate = useNavigate()

  const params = useParams<{ token: string }>()
  const { token = '' } = params

  const [user, setUser] = useState<UserObj>()

  useEffect(() => {
    const fetchUser = async () => {
      const response = await client.invite(token)

      if ('error' in response) {
        return console.log('TODO: HANDLE ERROR: ', response)
      }

      if (response.user.passwordSet) {
        if (!contextUser) {
          toast.warning('Invite link already accepted. Please log in.')
        }
        navigate('/')
      }

      setUser(response.user)
    }

    fetchUser()
  }, [token, navigate, contextUser])

  // If we have a context user, that means a password must have been set
  useEffect(() => {
    if (contextUser) navigate('/')
  }, [contextUser, navigate])

  if (!user) return null

  return <Password token={token} type={'set'} />
}

export default User
