/**
 * A wrapper around Chakra-UI's Input component. This automatically wraps the
 * input in a <FormControl> and adds a label if one is passed through as props
 *
 * Some decisions have been made around defaults, including:
 *   - making `FormControl` required
 *
 * Also worth noting there is a custom list of `types`, which will perform
 * certain functions, depending on the type selected
 */

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputProps as ChakraInputProps,
  InputRightElement,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react'
import { Text } from 'components'
import React, { useState } from 'react'

// All possible HTML input types
type InputType =
  | 'text'
  | 'password'
  | 'checkbox'
  | 'radio'
  | 'file'
  | 'date'
  | 'datetime-local'
  | 'time'
  | 'email'
  | 'url'
  | 'number'
  | 'tel'
  | 'search'
  | 'range'
  | 'color'

export type InputProps = (ChakraInputProps | TextareaProps) & {
  formControlProps?: FormControlProps
  label?: string
  errorMessage?: string
  type?: InputType | 'spreadsheet' | 'textarea' | 'select'
}

const Input: React.FC<InputProps> = ({
  id,
  label,
  formControlProps,
  errorMessage,
  type,
  ...props
}) => {
  const [show, setShow] = useState(false)

  /**
   * This is where the magic happens. Had to be set up in a semi hacky way,
   * to make `<Textarea>` and `<Input>` work together.
   *
   * If we want any other special scenarios with different types, we can add
   * them to the `type` prop, then add them to the logic below.
   */
  const renderInput = () => {
    if (type === 'textarea') {
      const castProps = props as TextareaProps

      return <Textarea id={id} name={id} resize={'vertical'} {...castProps} />
    } else {
      const castProps = props as ChakraInputProps

      if (type === 'spreadsheet') {
        return (
          <ChakraInput
            id={id}
            name={id}
            type={'file'}
            style={{ border: 0 }}
            accept={
              '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .numbers,application/vnd.apple.numbers'
            }
            {...castProps}
          />
        )
      }

      if (type === 'url') {
        return (
          <ChakraInput
            id={id}
            name={id}
            type={'url'}
            pattern="https://.*"
            placeholder={'https://example.com'}
            {...castProps}
          />
        )
      }

      return (
        <InputGroup>
          <ChakraInput
            id={id}
            name={id}
            type={type === 'password' ? (show ? 'text' : 'password') : type}
            {...castProps}
          />
          {type === 'password' ? (
            <InputRightElement>
              <Text
                type={'link'}
                mr={4}
                fontSize={`0.8rem`}
                onClick={() => setShow(!show)}
              >
                {show ? 'Hide' : 'Show'}
              </Text>
            </InputRightElement>
          ) : null}
        </InputGroup>
      )
    }
  }

  return (
    <FormControl isRequired {...formControlProps}>
      {label ? (
        <FormLabel htmlFor={id}>
          <Text type={'bold'} display={'inline'}>
            {label}
          </Text>
        </FormLabel>
      ) : null}

      {renderInput()}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default Input
