import FormWrapper from 'components/Admin/Forms/FormWrapper/FormWrapper'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { AddressObj } from 'types'
import { capitliseWord, COUNTRIES, STATES } from 'utils'

import fields from './fields'

interface Props {
  id: string
  onSubmitSuccess: (_address: AddressObj) => void
}

const Address: React.FC<Props> = ({ onSubmitSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [states, setStates] = useState([])

  const handleSubmit = async (data: any) => {
    setIsSubmitting(true)
    //TODO: Fix any
    try {
      const response = await client.createAddress(data)

      if ('error' in response) {
        throw response
      }

      onSubmitSuccess(response.address)
    } catch (error: any) {
      error.handled || toast.error(error?.message || 'Unable to set address.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCountryChange = (
    country: string,
    formValues: { [key: string]: string },
  ) => {
    const states = STATES[country]

    setStates(
      states.map((state: string) => ({
        id: state,
        name: state.toUpperCase(),
      })),
    )

    const stateValue = states.includes(formValues.state) ? formValues.state : ''

    return {
      ...formValues,
      state: stateValue,
    }
  }

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      submitButtonProps={{
        colorScheme: 'green',
        text: 'Register',
      }}
      fields={fields}
      selectOptions={{
        state: states,
        country: COUNTRIES.map((country) => ({
          id: country,
          name: capitliseWord(country),
          handleChange: handleCountryChange,
          hideOtherField: 'state',
        })),
      }}
    />
  )
}

export default Address
