import {
  Card,
  CenteredContainer,
  FormWrapper,
  Link,
  LogoHeader,
} from 'components'
import { useAuth } from 'hooks'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'

interface Props {
  token: string
  type: 'set' | 'reset'
}

const Password: React.FC<Props> = ({ token, type }) => {
  const { login } = useAuth()

  const [wasInvalidSubmission, setWasInvalidSubmission] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handlePasswordSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)

      const response = await client.setPassword(token, { ...data, type })

      if ('error' in response) {
        throw response
      }

      login(response.accessToken, response.user)

      toast.success('Password set successfully!')
    } catch (error: any) {
      error.handled || toast.error(error?.message || 'Unable to set password.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const buttonText = type === 'set' ? 'Create Password' : 'Reset Password'
  const subText =
    type === 'set'
      ? 'Please enter a password to activate your account'
      : 'Please enter the new password you wish to set on your account'

  return (
    <CenteredContainer>
      <Card
        header={<LogoHeader subText={subText} />}
        footer={
          type === 'reset' ? <Link to={'/'} text={'Back to login'} /> : null
        }
      >
        <FormWrapper
          onSubmit={handlePasswordSubmit}
          submitButtonProps={{
            text: buttonText,
            width: '100%',
          }}
          wasInvalidSubmission={wasInvalidSubmission}
          setWasInvalidSubmission={setWasInvalidSubmission}
          isSubmitting={isSubmitting}
          fields={[
            {
              id: 'password',
              label: 'Password',
              placeholder: 'Password',
              type: 'password',
            },
            {
              id: 'passwordConfirm',
              label: 'Confirm Password',
              placeholder: 'Confirm Password',
              type: 'password',
            },
          ]}
        />
      </Card>
    </CenteredContainer>
  )
}

export default Password
