import React, { createContext, useState } from 'react'
import { ModuleObj, OrganisationObj } from 'types'

/* Organisation Context */

interface OrganisationContextType {
  // Organisations
  organisations: OrganisationObj[]
  updateOrganisation: (data: OrganisationObj) => void
  deleteOrganisation: (id: string) => void
  initialSetOrganisations: (data: OrganisationObj[]) => void

  // Modules
  modules: ModuleObj[]
  updateModule: (data: ModuleObj) => void
  deleteModule: (id: string) => void
  initialSetModules: (data: ModuleObj[]) => void
}

// Create the context for the organisation object
const OrganisationContext = createContext<OrganisationContextType>({
  // Organisations
  organisations: [],
  updateOrganisation: () => {},
  deleteOrganisation: () => {},
  initialSetOrganisations: () => {},

  // Modules
  modules: [],
  updateModule: () => {},
  deleteModule: () => {},
  initialSetModules: () => {},
})

/* OrganisationProvider */

interface Props {
  children: React.ReactNode
}

// Create a provider component for the organisation context
const OrganisationProvider: React.FC<Props> = ({ children }) => {
  // Use state to track the current organisations & modules object
  const [organisations, setOrganisations] = useState<OrganisationObj[]>([])
  const [modules, setModules] = useState<ModuleObj[]>([])

  /* Organisations */

  const initialSetOrganisations = (data: OrganisationObj[]) => {
    setOrganisations(data)
  }

  const updateOrganisation = (data: OrganisationObj) => {
    const index = organisations.findIndex((q) => q.id === data.id)
    if (index >= 0) {
      // If the organisation already exists, update it
      setOrganisations([
        ...organisations.slice(0, index),
        data,
        ...organisations.slice(index + 1),
      ])
    } else {
      // If the organisation does not exist, add it to the end of the array
      setOrganisations([...organisations, data])
    }
  }

  const deleteOrganisation = (id: string) => {
    const filteredOrganisations = organisations.filter(
      (organisation) => organisation.id !== id,
    )
    setOrganisations([...filteredOrganisations])
  }

  /* Modules */

  const initialSetModules = (data: ModuleObj[]) => {
    setModules(data)
  }

  const updateModule = (data: ModuleObj) => {
    const index = modules.findIndex((q) => q.id === data.id)
    if (index >= 0) {
      // If the module already exists, update it
      setModules([
        ...modules.slice(0, index),
        data,
        ...modules.slice(index + 1),
      ])
    } else {
      // If the module does not exist, add it to the end of the array
      setModules([...modules, data])
    }
  }

  const deleteModule = (id: string) => {
    const filteredModules = modules.filter((module) => module.id !== id)
    setModules([...filteredModules])
  }

  // Pass the organisation object and functions down to child components
  return (
    <OrganisationContext.Provider
      value={{
        // Organisations
        organisations,
        updateOrganisation,
        initialSetOrganisations,
        deleteOrganisation,

        // Modules
        modules,
        updateModule,
        initialSetModules,
        deleteModule,
      }}
    >
      {children}
    </OrganisationContext.Provider>
  )
}

export { OrganisationContext, OrganisationProvider }
