import { api } from 'services'
import { UserWithAddressObj } from 'types'
import {
  AddressObj,
  ExistingUserWorkshopsResponsesObj,
  FetchResponse,
  QueryOptions,
  QuestionObj,
  UserObj,
  UserWithWorkshopObj,
  UserWorkshopsAPIObj,
  UserWorkshopsResponsesObj,
} from 'types'

const getMe = async (): Promise<
  FetchResponse<{ user: UserObj; accessToken: string }>
> => {
  return api.get('/user/me')
}

const updateMe = async (
  obj: UserWithAddressObj,
): Promise<FetchResponse<{ user: UserObj }>> => {
  return api.patch('/user/me', { data: obj })
}

const getUser = async (
  id: string,
  options: QueryOptions & { workshops: boolean } = { workshops: true },
): Promise<FetchResponse<{ user: UserWithWorkshopObj }>> => {
  return api.get(`/user/${id}`, {}, options)
}

const setPassword = async (
  token: string,
  obj: { password: string; passwordConfirm: string },
): Promise<FetchResponse<{ user: UserObj; accessToken: string }>> => {
  return api.patch(
    `/auth/reset_password/${token}`,
    {
      data: obj,
    },
    { credentials: 'include' },
  )
}

const createAddress = async (
  obj: AddressObj,
): Promise<FetchResponse<{ address: AddressObj }>> => {
  return api.post(`/user/me/address`, {
    address: obj,
  })
}

const getUserAddress = async (): Promise<
  FetchResponse<{ address: AddressObj }>
> => {
  return api.get('/user/me/address')
}

const getUserWorkshops = async (
  userId: string,
): Promise<FetchResponse<{ workshops: UserWorkshopsAPIObj[] }>> => {
  return api.get(`/user/${userId}/workshops`)
}

const getUserAssessments = async (
  workshopId: string,
  responseType: string,
): Promise<
  FetchResponse<{
    questions: QuestionObj[]
    userResponses?: ExistingUserWorkshopsResponsesObj[]
  }>
> => {
  return api.get(`/user/me/assessments/${responseType}/${workshopId}`)
}

const answerQuestion = async (
  data: UserWorkshopsResponsesObj,
  assessmentCompleted: boolean,
): Promise<
  FetchResponse<{
    answer: ExistingUserWorkshopsResponsesObj
    uw: UserWorkshopsAPIObj
  }>
> => {
  return api.post(
    `/user/me/assessments${assessmentCompleted ? '?completed=true' : ''}`,
    data,
  )
}

const funcs = {
  getMe,
  updateMe,
  getUser,
  setPassword,
  createAddress,
  getUserAddress,
  getUserWorkshops,
  getUserAssessments,
  answerQuestion,
}

export default funcs
