import { api } from 'services'
import {
  FetchResponse,
  ImportUsersType,
  NewUserObj,
  QueryOptions,
  UserObj,
  UserWorkshopsAPIObj,
} from 'types'

const getUsers = async (
  options: QueryOptions & { organisationId?: string } = {},
): Promise<FetchResponse<{ users: UserObj[] }>> => {
  return api.get(`/admin/users`, {}, options)
}

const updateUser = async (
  id: string,
  obj: NewUserObj,
): Promise<FetchResponse<{ user: UserObj }>> => {
  return api.patch(`/admin/users/${id}`, {
    user: obj,
  })
}

const postUser = async (
  user: NewUserObj,
): Promise<FetchResponse<{ user: UserObj }>> => {
  return api.post('/admin/users', { user: user })
}

const importUsers = async (
  users: NewUserObj[],
  workshopId?: string,
): Promise<FetchResponse<ImportUsersType>> => {
  return api.post('/admin/users/import', {
    users: users,
    workshopId: workshopId,
  })
}

const destroyUser = async (id: string): Promise<FetchResponse<{}>> => {
  return api.delete(`/admin/users/${id}`)
}

const addUserToWorkshop = async (
  userId: string,
  workshopId: string,
): Promise<FetchResponse<{ user: UserWorkshopsAPIObj }>> => {
  return api.post(`/admin/users/workshop`, { userId, workshopId })
}

const removeUserFromWorkshop = async (obj: {
  workshopId: string
  userId: string
}): Promise<FetchResponse<{}>> => {
  return api.get(`/admin/users/${obj.userId}/workshop/${obj.workshopId}/remove`)
}

const users = {
  getUsers,
  updateUser,
  postUser,
  importUsers,
  destroyUser,
  addUserToWorkshop,
  removeUserFromWorkshop,
}

export default users
