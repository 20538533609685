import { Container, ContainerProps } from '@chakra-ui/react'

interface Props extends ContainerProps {
  children: React.ReactElement<any, any>[] | React.ReactElement<any, any>
}

const CenteredContainer: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Container
      display={'flex'}
      flex={1}
      justifyContent={'center'}
      alignItems={'center'}
      {...rest}
    >
      {children}
    </Container>
  )
}

export default CenteredContainer
