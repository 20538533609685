import { Link, Text } from 'components'
import { WorkshopObj } from 'types'

interface Props {
  workshop: WorkshopObj
}

const PostWorkshopAvailableNotComplete: React.FC<Props> = ({ workshop }) => {
  return (
    <>
      <Text>
        {`Thank you for your participation in the ${workshop.module_name} module
        of the Being Leaders program. To concolude your development experience,
        please complete the following self reflection:`}
      </Text>
      <Link
        text={'Post Program Self Reflection'}
        to={`/user/workshop/${workshop.id}/post`}
      />
    </>
  )
}

export default PostWorkshopAvailableNotComplete
