import { InputProps } from 'components/UI/Input/Input'

const fields = [
  {
    id: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'your_email@address.com',
  },
  {
    id: 'password',
    label: 'Password',
    type: 'password',
    placeholder: '********',
  },
] as InputProps[]

export default fields
