import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  AccordionProps,
  Box,
} from '@chakra-ui/react'
import { Heading } from 'components'
import React from 'react'

type Props = AccordionProps & {
  items: {
    title: string
    isDisabled?: boolean
    expandedText?: string
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>
  }[]
  accordionItemProps?: AccordionItemProps
  hideIcon?: boolean
  children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>
}

const Accordion: React.FC<Props> = ({
  children,
  items,
  hideIcon,
  accordionItemProps,
  ...props
}) => {
  return (
    <ChakraAccordion allowMultiple {...props}>
      {items.map((item, i) => (
        <AccordionItem
          key={`${item.title}${i + 10}`}
          isDisabled={item.isDisabled}
          {...accordionItemProps}
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                <Box as={'span'} flex={'1'} textAlign={'left'}>
                  <Heading size={'sm'}>
                    {(isExpanded && item.expandedText) || item.title}
                  </Heading>
                </Box>
                {!hideIcon ? <AccordionIcon /> : null}
              </AccordionButton>
              <AccordionPanel pb={4}>{item.children}</AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}

      {children}
    </ChakraAccordion>
  )
}

export default Accordion
