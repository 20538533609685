import admin from './admin'
import auth from './auth'
import user from './user'

const client = {
  ...auth,
  ...admin,
  ...user,
}

export default client
