/**
 * TODO: BETTER COMMENT: This component wraps our table, handling pagination, sorting, and filtering
 */
import { Box, Flex } from '@chakra-ui/react'
import { Button, Heading, Input, Select, Table, Text } from 'components'
import { TableProps } from 'components/UI/Table/Table'
import React from 'react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TABLE_HEADERS } from 'utils'

const PAGE_SIZE = 20

// TODO GET TYPES WORKING CORRECTLY, WITHOUT any
// type AdminTableResponse<T> = { [K in keyof T]: T[K][] }

// interface Props<T> extends TableProps {
interface Props extends TableProps {
  // Table specific props
  heading: string

  type:
    | 'users'
    | 'workshops'
    | 'user'
    | 'workshop'
    | 'modules'
    | 'questions'
    | 'organisations'
  setParentData: (data: any) => void // TODO: FIX ANy!!!! // React.Dispatch<React.SetStateAction<T[]>>

  // initialDataFetch: () => Promise<FetchResponse<({ users: UserObj[] } | { workshops: WorkshopObj[]})>>
  initialDataFetch: any // TODO: FIX THIS TYPE () => Promise<FetchResponse<AdminTableResponse<T>>>
  SubHeading?: JSX.Element
  hideSearch?: boolean
  tableCaption?: string
  defaultSearchBy?: string
}

// const AdminTable = <T extends { [key: string]: any }>({
const AdminTable = ({
  data,
  type,
  initialDataFetch,
  setParentData,
  heading,
  SubHeading,
  hideSearch,
  defaultSearchBy,
  ...rest
}: Props) => {
  // }: Props<T>) => {
  // Table Headers
  const HEADERS = TABLE_HEADERS[type]
  const DEFAULT_SEARCH_BY =
    (defaultSearchBy &&
      HEADERS.find((h) => h.value === defaultSearchBy)?.value) ||
    HEADERS[0].value

  // extract the `value` property from the headers prop
  type SearchByValue = (typeof HEADERS)[number]['value']

  let [searchParams, setSearchParams] = useSearchParams()

  // Pagination
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get('page') || '1') || 1,
  )
  const [totalPages, setTotalPages] = useState(1)

  // These keep track of the state of the search objects
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get('term') || '',
  )
  const [searchBy, setSearchBy] = useState<SearchByValue>(
    searchParams.get('field') || DEFAULT_SEARCH_BY,
  )

  // These values are used when the `search` button is clicked
  const [termToSearch, setTermToSearch] = useState<string>(
    searchParams.get('term') || '',
  )
  const [fieldToSearch, setFieldToSearch] = useState<SearchByValue>(
    searchParams.get('field') || DEFAULT_SEARCH_BY,
  )

  // Date Picker
  const [date, setDate] = useState<{ start: string; end: string }>({
    start: '',
    end: '',
  })

  // Sort
  const [sortBy, setSoryBy] = useState<{
    key: string
    direction: 'desc' | 'asc' | ''
  }>({ key: '', direction: '' })

  // Loading
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const options = { page: currentPage } as {
        [key: string]: string | number
      }
      if (termToSearch) {
        options.term = termToSearch
        if (fieldToSearch) options.field = fieldToSearch
      }
      if (sortBy.key) {
        options.sortBy = sortBy.key
        options.sortDirection = sortBy.direction
      }

      const response = await initialDataFetch(options)

      if ('error' in response) {
        return response.handled
          ? null
          : toast.error(
              'Could not set data. Try refreshing, or try again later!',
            )
      }

      // Set data in this component, and the parent component, so other functions can be performed
      setParentData(response[type])
      setTotalPages(Math.ceil(response.count / PAGE_SIZE))
      setLoading(false)
    }

    const page = parseInt(searchParams.get('page') || '1')
    if (page === currentPage) {
      fetchData()
    }
  }, [
    type,
    initialDataFetch,
    setParentData,
    currentPage,
    fieldToSearch,
    termToSearch,
    searchParams,
    sortBy,
  ])

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || '1')

    if (page !== currentPage) {
      setCurrentPage(page > totalPages ? totalPages : page < 1 ? 1 : page)
    }
  }, [searchParams, currentPage, totalPages])

  const updatedSearchParams = (arr: { key: string; value: string }[]) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString())
    arr.forEach((item) => {
      updatedSearchParams.set(item.key, item.value)
    })
    setSearchParams(updatedSearchParams.toString())
  }

  const nextPage = () => {
    if (currentPage < totalPages) {
      updatedSearchParams([
        { key: 'page', value: (currentPage + 1).toString() },
      ])
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      updatedSearchParams([
        { key: 'page', value: (currentPage - 1).toString() },
      ])
    }
  }

  const handleSearch = async () => {
    const term =
      searchBy === 'session_1' ? `from${date.start}to${date.end}` : searchTerm

    // Clear Page from search Params
    setSearchParams({ term: term, field: searchBy })
    setFieldToSearch(searchBy)
    setTermToSearch(term)
  }

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'start' && !e.target.value) {
      return setDate({ end: '', start: '' })
    }
    setDate({ ...date, [e.target.name]: e.target.value })
  }

  const handleSearchByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.target.value === 'date'
      ? setSearchTerm('')
      : setDate({ start: '', end: '' })

    setSearchBy(e.target.value as SearchByValue)
  }

  const updateSortBy = (value: string) => {
    const newSortDir =
      sortBy.key === value && sortBy.direction === 'desc' ? 'asc' : 'desc'
    setSoryBy({ key: value, direction: newSortDir })
    updatedSearchParams([
      { key: 'sortBy', value: value },
      { key: 'sortDirection', value: newSortDir },
    ])
  }

  const SearchAndFilter = (
    <Box mt={'2'}>
      <Box mb={'3'}>
        <Heading textAlign={'center'} size={'lg'}>
          {heading}
        </Heading>
        {SubHeading ? SubHeading : null}
      </Box>
      {hideSearch ? null : (
        <Flex alignItems={'center'}>
          <Box mr={'2'}>
            {searchBy === 'session_1' ? (
              <Flex alignItems={'center'}>
                <Text mr={'1'}>{'From:'}</Text>
                <Input
                  name={'start'}
                  value={date.start}
                  onChange={handleDateChange}
                  type={'date'}
                  max={date.end || ''}
                />
                <Text mr={'1'}>{'To:'}</Text>
                <Input
                  name={'end'}
                  value={date.end}
                  onChange={handleDateChange}
                  type={'date'}
                  isDisabled={!date.start}
                  min={date.start}
                />
              </Flex>
            ) : (
              <Input
                onChange={handleSearchTermChange}
                value={searchTerm}
                placeholder={'Search'}
                type={
                  searchBy === 'status' || searchBy === 'question_order'
                    ? 'number'
                    : 'text'
                }
              />
            )}
          </Box>
          <Box mr={'2'}>
            <Select value={searchBy} onChange={handleSearchByChange}>
              {HEADERS.map((h, i) =>
                h.hideFromSearch ? (
                  <React.Fragment key={h.value}></React.Fragment>
                ) : (
                  <option key={`${i}header`} value={h.value}>
                    {h.title}
                  </option>
                ),
              )}
            </Select>
          </Box>
          <Button onClick={handleSearch} colorScheme={'blue'}>
            {'Search'}
          </Button>
        </Flex>
      )}
    </Box>
  )

  return (
    <Table
      headers={HEADERS}
      data={data}
      prevPage={prevPage}
      nextPage={nextPage}
      currentPage={currentPage}
      totalPages={totalPages}
      HeaderComponent={SearchAndFilter}
      onHeaderClick={updateSortBy}
      loading={loading}
      {...rest}
    />
  )
}

export default AdminTable
