import { useDisclosure } from '@chakra-ui/react'
import { AdminTable, Modal, WorkshopForm } from 'components'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { client } from 'services'
import { WorkshopObj } from 'types'
import { formatWorkshop } from 'utils'

const Workshops: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  const [workshops, setWorkshops] = useState<WorkshopObj[]>([])

  const setData = useCallback((workshops: WorkshopObj[]) => {
    setWorkshops((workshops || []).map((w) => formatWorkshop(w)))
  }, [])

  const onSuccessfulCreate = (data: any) => {
    const workshopAsArr = workshops || []
    const formattedWorkshop = formatWorkshop(data)
    const allWorkshops = [...workshopAsArr, formattedWorkshop]

    // TODO: NEEDS FIXING
    setWorkshops(
      allWorkshops,
      // allWorkshops.sort((a, b) =>
      //   a.session_1.localeCompare(b.session_1, 'en-GB'),
      // ),
    )
    onClose()
  }

  const onSuccessfulDelete = (id: string) => {
    const filteredWorkshops = (workshops || []).filter(
      (workshop) => workshop.id !== id,
    )
    setWorkshops([...filteredWorkshops])
    onClose()
  }

  const handleRowClick = (index: number) => {
    navigate(`/admin/workshops/${(workshops || [])[index].id}`)
  }

  if (!workshops) return null

  return (
    <>
      <AdminTable
        heading={'Workshops'}
        data={workshops}
        initialDataFetch={client.getWorkshops}
        type={'workshops'}
        onRowClick={handleRowClick}
        setParentData={setData}
        onButtonClick={onOpen}
        defaultSearchBy={'module_name'}
      />

      <Modal
        title={'Create New Workshop'}
        isOpen={isOpen}
        onClose={onClose}
        hideButtons={true}
      >
        <WorkshopForm
          onSuccessfulCreate={onSuccessfulCreate}
          onSuccessfulDelete={onSuccessfulDelete}
        />
      </Modal>
    </>
  )
}

export default Workshops
