/**
 * TODO: COMMENT
 * Wrapper around Chakra-UI Table component
 *
 * Most props are self explanatory, but:
 *   * By passing a value for `onRowClick`, you can make the table rows clickable
 */

import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Table as ChakraTable,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  Button,
  Cross,
  Heading,
  Loading,
  Modal,
  Text,
  TickBox,
} from 'components'
import { useState } from 'react'
import { formatDate } from 'utils'

export interface TableProps {
  data: any[] // TODO: FIX any
  tableCaption?: string
  onRowClick?: (index: number) => void
  onButtonClick?: () => void
  noDataMessage?: string
  removeUserFromWorkshopSubmit?: (userId: string) => Promise<void>
}

interface Props extends TableProps {
  HeaderComponent: React.ReactNode
  headers: { title: string; value: string }[]
  prevPage: () => void
  nextPage: () => void
  currentPage: number
  totalPages: number
  onHeaderClick: (value: string) => void
  loading?: boolean
}

interface SortDirection {
  [key: string]: 'desc' | 'asc'
}

const Table: React.FC<Props> = ({
  HeaderComponent,
  data,
  headers,
  prevPage,
  nextPage,
  currentPage,
  totalPages,
  onHeaderClick,
  tableCaption = '',
  onRowClick,
  onButtonClick,
  noDataMessage,
  removeUserFromWorkshopSubmit,
  loading,
}) => {
  // Remove user from workshop modal
  const [removeUserModal, setRemoveUserModal] = useState<any>()

  // Sort By State
  const [sortBy, setSoryBy] = useState<SortDirection>({})

  const setSortIcon = (value: string) => {
    const newSortBy = sortBy[value] === 'desc' ? 'asc' : 'desc'
    setSoryBy({ [value]: newSortBy })
  }

  // Ensure we catch the click, and open the modal
  const handleRemoveClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any,
  ) => {
    e.stopPropagation()
    setRemoveUserModal(item)
  }

  return (
    <Card>
      <CardHeader pt={'0'}>{HeaderComponent}</CardHeader>
      <CardBody pt={'0'}>
        <Divider />

        <TableContainer alignContent={'center'}>
          <ChakraTable
            variant={loading ? 'unstyled' : 'striped'}
            colorScheme={'gray'}
          >
            <Thead>
              <Tr>
                {headers.map((header, index) => (
                  <Th key={`${header.value}${index}`}>
                    <Flex>
                      <Text
                        textTransform={'lowercase'}
                        type={'bold'}
                        style={{
                          transform:
                            sortBy[header.value] === 'desc'
                              ? 'translate(-25%, -5%) rotate(180deg)'
                              : 'translate(-15%, -5%)',
                        }}
                      >{`${sortBy[header.value] ? '^' : ''}`}</Text>
                      <Heading
                        onClick={() => {
                          setSortIcon(header.value)
                          onHeaderClick && onHeaderClick(header.value)
                        }}
                        cursor={onRowClick ? 'pointer' : 'default'}
                        size={'xs'}
                      >{`${header.title}`}</Heading>
                      <span style={{ flex: 1 }} />
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>

            {loading ? (
              <Tbody justifyContent={'center'}>
                <Tr>
                  <Td colSpan={headers.length}>
                    <Loading width={'80%'} />
                  </Td>
                </Tr>
              </Tbody>
            ) : (
              <Tbody>
                {!data
                  ? null
                  : data.map((item, index) => (
                      <Tr
                        key={`${item.id}${index}`}
                        onClick={() =>
                          !item.disabled && onRowClick && onRowClick(index)
                        }
                        cursor={
                          onRowClick && !item.disabled ? 'pointer' : 'default'
                        }
                        opacity={item.disabled ? 0.5 : 1}
                      >
                        {headers.map((header, i) => {
                          if (header.value === 'status') {
                            return (
                              <Td key={`${item[header.value]}${i}${index}`}>
                                <Badge>{item[header.value]}</Badge>
                              </Td>
                            )
                          }

                          if (header.value === 'removeUserFromWorkshop') {
                            return (
                              <Td key={`${item[header.value]}${i}${index}`}>
                                <Button
                                  onClick={(e) => handleRemoveClick(e, item)}
                                >
                                  {'Remove'}
                                </Button>
                              </Td>
                            )
                          }

                          return (
                            <Td key={`${item[header.value]}${i}${index}`}>
                              {item[header.value] === false ? (
                                <Cross />
                              ) : item[header.value] === true ? (
                                <TickBox />
                              ) : typeof item[header.value] === 'string' ? (
                                <Text>
                                  {header.value === 'session_1'
                                    ? formatDate(
                                        item[header.value] ||
                                          item['ftf_workshop_date'],
                                      )
                                    : item[header.value] || ''}
                                </Text>
                              ) : (
                                item[header.value]
                              )}
                            </Td>
                          )
                        })}
                      </Tr>
                    ))}
              </Tbody>
            )}

            <TableCaption>
              {!data?.length
                ? noDataMessage || 'No Data'
                : tableCaption ||
                  'Click on row to see more details, edit or delete'}

              <Box mt={'2'}>
                <Box>
                  <Button
                    isDisabled={currentPage === 1}
                    mr={'2'}
                    onClick={prevPage}
                  >
                    {'<'}
                  </Button>
                  <Button
                    isDisabled={currentPage === totalPages || !totalPages}
                    onClick={nextPage}
                  >
                    {'>'}
                  </Button>
                </Box>
                <Box mt={'2'}>
                  <Text>{`Page ${currentPage} of ${totalPages || 1}`}</Text>
                </Box>
              </Box>
            </TableCaption>
          </ChakraTable>
        </TableContainer>

        <Modal
          title={'Remove user from workshop'}
          isOpen={removeUserModal}
          onClose={() => setRemoveUserModal(null)}
          text={`Are you sure you want to remove ${removeUserModal?.first_name} from this workshop?`}
          handleSubmit={async () => {
            removeUserFromWorkshopSubmit &&
              (await removeUserFromWorkshopSubmit(removeUserModal?.id))
            setRemoveUserModal(null)
          }}
          confirmText={'Remove'}
        />

        {onButtonClick ? (
          <Button float={'right'} onClick={onButtonClick} colorScheme={'green'}>
            {'New'}
          </Button>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default Table
