import { Container } from '@chakra-ui/react'
import { Card, Text } from 'components'
import { useAuth } from 'hooks'
import React from 'react'
import { STATUSES } from 'utils'

import NoActionRequired from './NoActionRequired'
import PostWorkshopAvailableNotComplete from './PostWorkshopAvailableNotComplete'
import PostWorkshopCompleted from './PostWorkshopCompleted'
import PreWorkshopAvailableNotComplete from './PreWorkshopAvailableNotComplete'
import PreWorkshopCompleted from './PreWorkshopCompleted'

const Dashboard: React.FC = () => {
  const { user } = useAuth()

  if (!user || !user.workshops || !user.workshops.length) {
    return (
      <Card>
        <Text>{'Not currently enrolled in any workshops'}</Text>
      </Card>
    )
  }

  return (
    <Container>
      {user?.workshops?.length ? (
        user.workshops.map((workshop) => {
          const props = { workshop: workshop }

          return (
            <Card key={workshop.id} header={workshop.module_name} mb={3}>
              {!workshop.pre_workshop_available ? (
                <NoActionRequired {...props} />
              ) : workshop.status <= STATUSES.ADDRESS_COMPLETE ? (
                <PreWorkshopAvailableNotComplete {...props} />
              ) : !workshop.post_workshop_available ? (
                <PreWorkshopCompleted {...props} />
              ) : workshop.status === STATUSES.PRE_WORKSHOP_COMPLETE ? (
                <PostWorkshopAvailableNotComplete {...props} />
              ) : (
                <PostWorkshopCompleted {...props} />
              )}
            </Card>
          )
        })
      ) : (
        <></>
      )}
    </Container>
  )
}

export default Dashboard
