import { Box, Flex, Spacer } from '@chakra-ui/react'
import { Button, Link } from 'components'
import adminRoutes from 'components/navigation/AppRouter/adminRoutes'
import { useAuth, useWindowSize } from 'hooks'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { variables } from 'styles'
import { LARGE_SCREEN_SIZE } from 'utils'

interface Props {
  text: string
  onClick: () => void
}

const CustomButon: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Button
      id={`${text.toLocaleLowerCase().replaceAll(' ', '_')}_button`}
      colorScheme={'transparent'}
      onClick={onClick}
      _hover={{ color: variables.colours.font.hover }}
    >
      {text}
    </Button>
  )
}

const Navbar: React.FC = () => {
  // Hooks
  const { user, logout } = useAuth()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { windowSize } = useWindowSize()

  const isAdmin = user?.role === 'admin'

  const difference = (windowSize.width || 0) - LARGE_SCREEN_SIZE

  return user ? (
    <Flex
      p={3}
      bg={variables.colours.primary.base}
      mt={-variables.spacing.app}
      mb={6}
      alignItems={'center'}
      shadow={'md'}
      mx={difference > 0 ? -(difference / 2) : -variables.spacing.app}
      px={difference > 0 ? difference / 2 : variables.spacing.app}
    >
      <>
        {isAdmin ? (
          adminRoutes.map((route, index) =>
            route.name ? (
              <Box px={2} key={`${route.path}${index}`}>
                <Link
                  to={route.path}
                  text={route.name}
                  TextProps={{
                    color:
                      pathname.replace('/', '') === route.path
                        ? variables.colours.font.black
                        : variables.colours.font.white,
                  }}
                />
              </Box>
            ) : null,
          )
        ) : pathname !== '/' ? (
          <CustomButon
            text={'< Dashboard'}
            onClick={() => navigate('/', { replace: true })}
          />
        ) : null}

        <Spacer flex={1} />

        {isAdmin || !user.hasAddress ? null : (
          <CustomButon text={'Account'} onClick={() => navigate('/account')} />
        )}

        <CustomButon text={'Log out'} onClick={logout} />
      </>
    </Flex>
  ) : null
}

export default Navbar
