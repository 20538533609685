import { toast } from 'react-toastify'
import { FetchResponse, QueryOptions, UnsuccessfulResponse } from 'types'

const BASE_URL = process.env.REACT_APP_API_URL

const getToken = () => {
  return localStorage.getItem('accessToken')
}

const clearToken = () => {
  localStorage.removeItem('accessToken')
  window.dispatchEvent(new StorageEvent('storage', { key: 'accessToken' }))
}

const getHeaders = (additionalHeaders = {}) => {
  const accessToken = getToken()

  return {
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...additionalHeaders,
    },
  }
}

const fetchWrapper = async <T>(
  url: string,
  options: RequestInit = {},
): Promise<FetchResponse<T>> => {
  const response = await fetch(url, options)

  if (!response.ok) {
    // As the name suggests, our access token has expires, so we must refresh it
    if (response.headers.get('X-access-token-expired')) {
      try {
        // This API call is a GET, so we must pull the body off the initial request
        const { body: _body, ...rest } = options

        // Hit special endpoint to refresh token
        const refreshResp = await fetch(BASE_URL + '/auth/refresh_token', {
          ...rest,
          method: 'GET',
          credentials: 'include',
        })

        // If we get an error, clear the token and return the error
        if (!refreshResp.ok) {
          clearToken()

          return {
            error: true,
            message: refreshResp.statusText,
            status: refreshResp.status,
          }
        }

        // If we are successful, get the new token
        const newToken = (await refreshResp.json()) as unknown as {
          accessToken: string
        }

        // Set new accessToken
        localStorage.setItem('accessToken', newToken.accessToken)

        // Complete initial request with new headers
        return await fetchWrapper(url, { ...options, ...getHeaders() })
      } catch (error) {
        clearToken()

        return {
          error: true,
          message: 'Unauthorised',
          status: 401,
        }
      }
    }

    const jsonResp = await response.json()

    const errorObj = {
      error: true,
      message: jsonResp?.error?.message || response.statusText,
      status: response.status,
    } as UnsuccessfulResponse

    // If there is an unauthorised error, clear the token
    if (response.status === 401) {
      clearToken()
    }

    if (response.status === 500) {
      toast.error(
        errorObj.message ||
          'Internal Server Error. Please try again later. If problem persists, please contact support.',
      )

      return { ...errorObj, handled: true }
    }

    return errorObj
  }

  return await response.json()
}

const api = {
  get: async <T>(
    url: string,
    options: RequestInit = {},
    queryOptions: QueryOptions = {},
  ): Promise<FetchResponse<T>> => {
    const allOptions = {
      ...options,
      ...getHeaders(options.headers),
    }
    const query = new URLSearchParams(queryOptions)
    const endpoint = `${BASE_URL}${url}${
      query.toString() ? `?${query.toString()}` : ''
    }`

    return await fetchWrapper(endpoint, allOptions)
  },
  post: async <T>(
    url: string,
    data: any,
    options: RequestInit = {},
    queryOptions: QueryOptions = {},
  ): Promise<FetchResponse<T>> => {
    const allOptions = {
      ...options,
      method: 'POST',
      ...getHeaders({
        ...options.headers,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    }
    const query = new URLSearchParams(queryOptions)
    const endpoint = `${BASE_URL}${url}${
      query.toString() ? `?${query.toString()}` : ''
    }`

    return await fetchWrapper(endpoint, allOptions)
  },
  put: async <T>(
    url: string,
    data: any,
    options: RequestInit = {},
    queryOptions: QueryOptions = {},
  ): Promise<FetchResponse<T>> => {
    const allOptions = {
      ...options,
      method: 'PUT',
      ...getHeaders({
        ...options.headers,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    }
    const query = new URLSearchParams(queryOptions)
    const endpoint = `${BASE_URL}${url}${
      query.toString() ? `?${query.toString()}` : ''
    }`

    return await fetchWrapper(endpoint, allOptions)
  },
  patch: async <T>(
    url: string,
    data: any,
    options: RequestInit = {},
    queryOptions: QueryOptions = {},
  ): Promise<FetchResponse<T>> => {
    const allOptions = {
      ...options,
      method: 'PATCH',
      ...getHeaders({
        ...options.headers,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    }
    const query = new URLSearchParams(queryOptions)
    const endpoint = `${BASE_URL}${url}${
      query.toString() ? `?${query.toString()}` : ''
    }`

    return await fetchWrapper(endpoint, allOptions)
  },
  delete: async <T>(
    url: string,
    options: RequestInit = {},
    queryOptions: QueryOptions = {},
  ): Promise<FetchResponse<T>> => {
    const allOptions = {
      ...options,
      method: 'DELETE',
      ...getHeaders(options.headers),
    }
    const query = new URLSearchParams(queryOptions)
    const endpoint = `${BASE_URL}${url}${
      query.toString() ? `?${query.toString()}` : ''
    }`

    return await fetchWrapper(endpoint, allOptions)
  },
}

export default api
