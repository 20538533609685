import { useAuth } from 'hooks'
import React from 'react'
import { Navigate } from 'react-router-dom'

type Props = {
  redirectPath?: string
  children: React.ReactElement<any, any>
}

const UnauthorisedRoute: React.FC<Props> = ({
  redirectPath = '/',
  children,
}) => {
  const { user } = useAuth()

  if (user) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

export default UnauthorisedRoute
