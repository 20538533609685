/**
 * A component to invite either a single user or all users of a workshop.
 * Can be used for the Initial Invite or the Follow Up Invite.
 */

import { Button, Modal } from 'components'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import {
  UsersWorkshopsStatus,
  WorkshopDelivery,
  WorkshopObjWithUsersAndOrganisation,
} from 'types'

// Values to populate the modal and buttons based on the type of invite
const DYNAMIC_VALUES = {
  workshop: {
    initial: {
      buttonText: 'Invite Users',
      modalTitle: 'Invite All Users',
      modalText:
        'Are you sure you want to send an invitation email to all users in this workshop?',
    },
    reminder: {
      buttonText: 'Remind Users',
      modalTitle: 'Remind All Users',
      modalText:
        'Are you sure you want to send a reminder email to all users in this workshop?',
    },
  },
  user: {
    initial: {
      buttonText: 'Send Invite',
      modalTitle: 'Invite User',
      modalText: 'Are you sure you want to send an invitation email?',
    },
    reminder: {
      buttonText: 'Send Follow Up',
      modalTitle: 'Remind User',
      modalText: 'Are you sure you want to send a reminder email?',
    },
  },
}

type TypeOptions = 'initial' | 'reminder'

interface Props extends WorkshopDelivery {
  workshopId: string
  id?: string
  type?: TypeOptions
  onSuccess: (
    data: WorkshopObjWithUsersAndOrganisation | {},
    options: {
      updateUser?: boolean
      userId?: string
      status?: UsersWorkshopsStatus
    },
  ) => void
}

const SendInvite: React.FC<Props> = ({
  id,
  workshopId,
  type = 'initial',
  onSuccess,
  delivery,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  // Ensure we catch the click, and open the modal
  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    setShowModal(true)
  }

  // Handle submit if we are inviting entire workshop
  const handleWorkshopSubmit = async () => {
    setLoading(true)

    const response = await client.sendInviteEmailToWorkshop({
      isReminder: type === 'reminder',
      workshopId: workshopId,
      delivery: delivery,
    })

    if ('error' in response) {
      return console.log('TODO: HANDLE ERROR: ', response)
    }

    const { successfulSends, unsuccessfulSends, count } = response

    if (
      successfulSends.length === 0 &&
      count === 0 &&
      unsuccessfulSends.length === 0
    )
      return toast.warning(
        'Could not invite any users, as all users have already accepted the invite',
      )

    toast.success(
      `Successfully invited ${
        successfulSends.length === count
          ? 'all users'
          : `${successfulSends.length} users`
      }`,
    )

    onSuccess(response.workshop, { updateUser: true })
    setLoading(false)
    setShowModal(false)
  }

  // Handle Submit if we are inviting a single user
  const handleUserSubmit = async () => {
    setLoading(true)

    const response = await client.sendInviteEmailToUser({
      isReminder: type === 'reminder',
      workshopId: workshopId,
      id: id || '',
      delivery: delivery,
    })

    if ('error' in response) {
      return console.log('TODO: HANDLE ERROR: ', response)
    }

    onSuccess(
      {},
      {
        updateUser: true,
        userId: response.userId,
        status: response.newStatus,
      },
    )

    toast.success(
      type === 'initial'
        ? 'User successfully invited!'
        : 'User successfully reminded!',
    )
    setLoading(false)
    setShowModal(false)
  }

  const obj = DYNAMIC_VALUES[id ? 'user' : 'workshop'][type]

  return (
    <>
      <Button
        isDisabled={loading}
        onClick={handleClick}
        colorScheme={type === 'initial' ? 'green' : 'orange'}
      >
        {obj.buttonText}
      </Button>

      <Modal
        title={obj.modalTitle}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        text={obj.modalText}
        handleSubmit={id ? handleUserSubmit : handleWorkshopSubmit}
        confirmText={'Invite'}
        submitLoadingText={loading ? 'Sending' : ''}
      />
    </>
  )
}

export default SendInvite
