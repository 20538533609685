import { FormWrapper } from 'components'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { Indexable, NewOrganisationObj, OrganisationObj } from 'types'

import fields from './fields'

type Props = {
  onSuccessfulCreate: (organisation: OrganisationObj) => void
  onSuccessfulDelete?: (id: string) => void
  editing?: boolean
  organisation?: OrganisationObj | null
}

type FormData = Indexable & NewOrganisationObj

const Organisation: React.FC<Props> = ({
  onSuccessfulCreate,
  editing,
  organisation,
  ...rest
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (data: FormData) => {
    try {
      setIsSubmitting(true)

      const response = editing
        ? await client.updateOrganisation(data.id, data)
        : await client.postOrganisation(data)

      if ('error' in response) {
        throw response
      }

      toast.success(
        editing
          ? 'Organisation created updated!'
          : 'Organisation created successfully!',
      )
      onSuccessfulCreate(response.organisation)
    } catch (error: any) {
      error.handled ||
        toast.error(
          error?.message || 'Something went wrong! Please try again later.',
        )
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <FormWrapper
      apiDeleteFunction={client.destroyOrganisation}
      onSubmit={onSubmit}
      data={organisation}
      fields={fields}
      editing={editing}
      isSubmitting={isSubmitting}
      {...rest}
    />
  )
}

export default Organisation
