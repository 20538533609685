/**
 * Routes only accessible by an authenticated USER
 */

import { useAuth } from 'hooks'
import React from 'react'
import { Navigate } from 'react-router-dom'

type Props = {
  redirectPath?: string
  children: React.ReactElement<any, any>
}

const ProtectedRoute: React.FC<Props> = ({ redirectPath = '/', children }) => {
  const { user } = useAuth()

  // Prevent admin users from accessing user routes
  if (!user || user?.role !== 'user') {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

export default ProtectedRoute
