import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react'

type Props = BoxProps & {
  style?: FlexProps
}

const Loading: React.FC<Props> = ({ style, ...props }) => {
  return (
    <Flex w={'100%'} justifyContent={'center'} {...style}>
      <Box
        width={'50%'}
        padding={'6'}
        m={4}
        boxShadow={'lg'}
        bg={'white'}
        borderRadius={'md'}
        {...props}
      >
        <SkeletonCircle size={'20'} />
        <SkeletonText
          mt={'5'}
          noOfLines={4}
          spacing={'3'}
          skeletonHeight={'4'}
        />
      </Box>
    </Flex>
  )
}

export default Loading
