/**
 * Currently, this component does not do much. It merely wraps the Chakra-UI
 * Heading, without adding extra functionality, but, in future, can be set up
 * to make our own heading component
 */

import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/react'
import React from 'react'

type Props = HeadingProps & {
  children: string
}

const Heading: React.FC<Props> = ({ children, ...props }) => {
  const obj = {
    fontFamily: 'Montserrat',
  } as HeadingProps

  return (
    <ChakraHeading {...obj} {...props}>
      {children}
    </ChakraHeading>
  )
}

export default Heading
