import {
  AdminDashboard,
  AdminUser,
  AdminWorkshop,
  AdminWorkshops,
  Modules,
  Organisations,
  ReflectionQuestions,
  Users,
} from 'pages'

const adminRoutes = [
  { name: 'Dashboard', path: 'admin', element: AdminDashboard },
  {
    name: 'Organisations',
    path: 'admin/organisations',
    element: Organisations,
  },
  {
    name: 'Modules',
    path: 'admin/modules',
    element: Modules,
  },
  { name: 'Workshops', path: 'admin/workshops', element: AdminWorkshops },
  { name: 'Users', path: 'admin/users', element: Users },

  /**
   * The following routes are missing a `name`, as we do not want to display
   * them in the navbar
   */
  {
    path: 'admin/reflection-questions/:module_id',
    element: ReflectionQuestions,
  },
  { name: '', path: 'admin/workshops/:id', element: AdminWorkshop },
  { name: '', path: 'admin/users/:id', element: AdminUser },
]

export default adminRoutes
