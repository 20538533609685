/**
 * By the time we get to the reminder / summary emails, we can assume
 * that these will be done on a workshop level, as such, will not handle
 * a single user case.
 * If there is a need, it can be achieved directly through sendgrid.
 */

import { Button, Modal, Text } from 'components'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import {
  UsersWorkshopsStatus,
  WorkshopDelivery,
  WorkshopEmailTypes,
  WorkshopObjWithUsersAndOrganisation,
} from 'types'

// Values to populate the modal and buttons based on the type of invite
const DYNAMIC_VALUES = {
  pre_workshop: '3. Being Leaders pre program self reflection',
  understand_role: '4. Understanding your role as a leader of leaders',
  s1_reminder: '5. Reminder for Being Leaders workshop',
  s1_summary: '6. Creating Sandboxes: thank you for your participation',
  s2_reminder: '7. Reminder for Being Leaders workshop',
  s2_summary: '8. Share your experience of the Being Leaders program',
  reflection: '9. Being Leaders reflection workshop reminder',
  reflection_reminder: '10. Being Leaders reflection workshop reminder',
  post_workshop: '11. Being Leaders post program self reflection',
}

interface Props extends WorkshopDelivery {
  type: WorkshopEmailTypes
  workshopId: string
  colorScheme?: string
  reflection_link?: string
  session_reflection?: string

  onSuccess: (
    data: WorkshopObjWithUsersAndOrganisation | {},
    options?: {
      updateUser?: boolean
      userId?: string
      status?: UsersWorkshopsStatus
    },
  ) => void
}

const SendWorkshopEmail: React.FC<Props> = ({
  workshopId,
  type,
  colorScheme = 'secondary',
  reflection_link,
  session_reflection,
  onSuccess,
  delivery,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  // Ensure we catch the click, and open the modal
  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()

    // Ensure we have set reflection details before opening the modal
    // If we are of type reflection or post_workshop
    if (type === 's2_summary' && !session_reflection) {
      return toast.error(
        'Please ensure you have set the reflection date and time before sending this email.',
      )
    }
    if (
      (type === 'reflection' || type === 'post_workshop') &&
      (!reflection_link || !session_reflection)
    ) {
      return toast.error(
        'Please ensure you have set the reflection date and time and reflection link before sending this email.',
      )
    }

    setShowModal(true)
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)

      const response = await client.sendWorkshopEmail({
        workshopId: workshopId,
        type: type,
        delivery: delivery,
      })

      if ('error' in response) throw response

      toast.success(`Successfully sent email!`)
      onSuccess(response.workshop)
    } catch (error: any) {
      toast.error(
        error?.message?.message || error?.message || 'Something went wrong',
      )
    } finally {
      setLoading(false)
      setShowModal(false)
    }
  }

  const isPrePostActivation =
    type === 'pre_workshop' || type === 'post_workshop'

  return (
    <>
      <Button
        isDisabled={loading}
        onClick={handleClick}
        colorScheme={colorScheme}
      >
        {`Send Email${isPrePostActivation ? ' and Activate' : ''}`}
      </Button>

      <Modal
        title={`Send Email${isPrePostActivation ? ' and Activate' : ''}`}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        handleSubmit={handleSubmit}
        confirmText={'Send'}
        submitLoadingText={loading ? 'Sending' : ''}
      >
        <>
          <Text display={'inline'}>{'Are you sure you want to send the '}</Text>
          <Text type={'bold'} display={'inline'}>
            {`"${DYNAMIC_VALUES[type]}"`}
          </Text>
          <Text display={'inline'}>
            {' email to all users in the workshop?'}
          </Text>
          {isPrePostActivation ? (
            <Text>{'This will also active the workshop assessment.'}</Text>
          ) : null}
        </>
      </Modal>
    </>
  )
}

export default SendWorkshopEmail
