import { FormWrapper } from 'components'
import { useOrganisation } from 'hooks'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { Indexable, NewUserObj, UserObj } from 'types'

import fields from './fields'

type Props = {
  onSuccessfulCreate: (user: UserObj) => void
  onSuccessfulDelete?: (id: string) => void
  editing?: boolean
  user?: UserObj | null
}

type FormData = Indexable & NewUserObj

const User: React.FC<Props> = ({
  onSuccessfulCreate,
  editing,
  user,
  ...rest
}) => {
  const { organisations } = useOrganisation()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (data: FormData) => {
    try {
      setIsSubmitting(true)

      const response = editing
        ? await client.updateUser(data.id, data)
        : await client.postUser(data)

      if ('error' in response) {
        throw response
      }

      const updatedUser = {
        ...response.user,
        organisation_name:
          organisations.find((o) => o.id === response.user.organisation_id)
            ?.name || '',
      }
      toast.success(
        editing ? 'User updated successfully!' : 'User created successfully!',
      )
      onSuccessfulCreate(updatedUser)
    } catch (error: any) {
      error.handled ||
        toast.error(
          error?.message || 'Something went wrong! Please try again later.',
        )
    } finally {
      setIsSubmitting(false)
    }
  }

  /* Return */

  return (
    <FormWrapper
      apiDeleteFunction={client.destroyUser}
      onSubmit={onSubmit}
      data={user}
      editing={editing}
      isSubmitting={isSubmitting}
      fields={fields}
      selectOptions={{
        role: [
          { id: 'user', name: 'User' },
          { id: 'admin', name: 'Admin' },
        ],
        organisation_id: organisations,
      }}
      {...rest}
    />
  )
}

export default User
