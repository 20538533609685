import { useDisclosure } from '@chakra-ui/react'
import { AdminTable, OrganisationForm } from 'components'
import { Modal } from 'components'
import { useOrganisation } from 'hooks'
import { useState } from 'react'
import { client } from 'services'
import { OrganisationObj } from 'types'

const Organisations: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { updateOrganisation, deleteOrganisation } = useOrganisation()

  const [organisations, setOrganisations] = useState<OrganisationObj[]>([])

  const [selectedOrganisation, setSelectedOrganisation] =
    useState<OrganisationObj | null>(null)

  const onSuccessfulCreate = async (data: any) => {
    updateOrganisation(data)

    // Set new organisations, by placing the new / edited organisation in the correct place,
    // and sorting the array alphabetically
    const newOrganisations = !!selectedOrganisation
      ? (organisations || []).map((d) => (d.id === data.id ? data : d))
      : [...(organisations || []), data]
    setOrganisations(
      newOrganisations.sort((a, b) => a.name.localeCompare(b.name)),
    )
    // setOrganisations((prev) => [...(prev || []), data])

    setSelectedOrganisation(null)
    onClose()
  }

  const onSuccessfulDelete = async (id: string) => {
    deleteOrganisation(id)
    setOrganisations((prev) =>
      (prev || []).filter((organisation) => organisation.id !== id),
    )

    setSelectedOrganisation(null)
    onClose()
  }

  const handleRowClick = (index: number) => {
    setSelectedOrganisation(organisations[index])
    onOpen()
  }

  return (
    <>
      <AdminTable
        heading={'Organisations'}
        data={organisations}
        initialDataFetch={client.getOrganisations}
        type={'organisations'}
        onRowClick={handleRowClick}
        setParentData={setOrganisations}
        onButtonClick={onOpen}
      />
      <Modal
        title={
          !!selectedOrganisation ? 'Organisation' : 'Create New Organisation'
        }
        isOpen={isOpen}
        onClose={() => {
          setSelectedOrganisation(null)
          onClose()
        }}
        hideButtons={true}
      >
        <OrganisationForm
          onSuccessfulCreate={onSuccessfulCreate}
          onSuccessfulDelete={onSuccessfulDelete}
          organisation={selectedOrganisation}
          editing={!!selectedOrganisation}
        />
      </Modal>
    </>
  )
}

export default Organisations
