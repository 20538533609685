import api from 'services/client/api'
import {
  FetchResponse,
  QueryOptions,
  UsersWorkshopsStatus,
  WorkshopDelivery,
  WorkshopEmailTypes,
  WorkshopObj,
  WorkshopObjWithUsersAndOrganisation,
} from 'types'

const sendInviteEmailToUser = async (
  obj: WorkshopDelivery & {
    id: string
    workshopId: string
    isReminder?: boolean
  },
): Promise<
  FetchResponse<{
    userId: string
    workshopId: string
    newStatus: UsersWorkshopsStatus
  }>
> => {
  return api.get(
    `/admin/users/${obj.id}/invite/${obj.workshopId}?delivery=${obj.delivery}${
      obj.isReminder ? '&isReminder=true' : ''
    }`,
  )
}

const sendInviteEmailToWorkshop = async (
  options: QueryOptions &
    WorkshopDelivery & {
      isReminder?: boolean
      workshopId: string
    },
): Promise<
  FetchResponse<{
    unsuccessfulSends: { email: string; reason: string }[]
    successfulSends: { id: string; status?: number }[]
    workshop: WorkshopObjWithUsersAndOrganisation
  }>
> => {
  const { workshopId, isReminder, delivery, ...rest } = options
  const obj = isReminder ? { isReminder: true } : {}
  const deliveryObj = { delivery: delivery }
  return api.get(
    `/admin/workshops/${workshopId}/invite`,
    {},
    { ...rest, ...obj, ...deliveryObj },
  )
}

const sendWorkshopEmail = async (
  options: WorkshopDelivery & {
    workshopId: string
    type: WorkshopEmailTypes
  },
): Promise<FetchResponse<{ workshop: WorkshopObj }>> => {
  return api.get(
    `/admin/workshops/${options.workshopId}/email?type=${options.type}&delivery=${options.delivery}`,
  )
}

const emails = {
  sendInviteEmailToUser,
  sendInviteEmailToWorkshop,
  sendWorkshopEmail,
}

export default emails
