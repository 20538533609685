import { ChakraProvider, Flex } from '@chakra-ui/react'
import { AppRouter, ToastWrapper } from 'components'
import { AlertProvider } from 'context'
import { useAuth, useOrganisation, useWindowSize } from 'hooks'
import React, { useEffect, useState } from 'react'
import { client } from 'services'
import { variables } from 'styles'
import theme from 'theme'
import { LARGE_SCREEN_SIZE } from 'utils'

const App: React.FC = () => {
  // Hooks
  const { user } = useAuth()
  const { initialSetOrganisations, initialSetModules } = useOrganisation()
  const { deviceType, windowSize } = useWindowSize()

  // State
  const [dataFetched, setDataFetched] = useState(false)

  useEffect(() => {
    if (!dataFetched && user?.role === 'admin') {
      /**
       * Can add any other app related data here
       */
      const fetchInitialData = async () => {
        const response = await Promise.all([
          client.getOrganisations(),
          client.getModules(),
        ])

        if ('error' in response[0] || 'error' in response[1]) {
          return console.log('TODO: HANDLE ERROR: ', response)
        }

        initialSetOrganisations(response[0].organisations || [])
        initialSetModules(response[1].modules || [])
        setDataFetched(true)
      }

      fetchInitialData()
    }
  }, [dataFetched, initialSetModules, initialSetOrganisations, user?.role])

  // Limit the content to a width of LARGE_SCREEN_SIZE, by adding padding
  // to anything over this number
  const appHorizontalPadding =
    deviceType === 'largeScreen'
      ? ((windowSize.width || 0) - LARGE_SCREEN_SIZE) / 2
      : variables.spacing.app

  return (
    <ChakraProvider theme={theme}>
      <AlertProvider>
        <Flex
          flexDirection={'column'}
          bg={
            deviceType === 'smallMobile' || deviceType === 'largeMobile'
              ? variables.colours.secondary.base
              : variables.colours.background.content
          }
          minH={'100dvh'}
          py={variables.spacing.app}
          px={appHorizontalPadding}
        >
          <ToastWrapper />
          <AppRouter />
        </Flex>
      </AlertProvider>
    </ChakraProvider>
  )
}

export default App
