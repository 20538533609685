import { api } from 'services'
import {
  FetchResponse,
  NewOrganisationObj,
  OrganisationObj,
  QueryOptions,
} from 'types'

const getOrganisations = async (
  options: QueryOptions = {},
): Promise<
  FetchResponse<{
    organisations: OrganisationObj[]
  }>
> => {
  return api.get('/admin/organisations', {}, options)
}

const postOrganisation = async (
  organisation: NewOrganisationObj,
): Promise<FetchResponse<{ organisation: OrganisationObj }>> => {
  return api.post('/admin/organisations', { organisation: organisation })
}

const updateOrganisation = async (
  id: string,
  obj: NewOrganisationObj,
): Promise<FetchResponse<{ organisation: OrganisationObj }>> => {
  return api.patch(`/admin/organisations/${id}`, {
    organisation: obj,
  })
}

const destroyOrganisation = async (id: string): Promise<FetchResponse<{}>> => {
  return api.delete(`/admin/organisations/${id}`)
}

const organisations = {
  getOrganisations,
  postOrganisation,
  updateOrganisation,
  destroyOrganisation,
}

export default organisations
