import { FormWrapper, Link, LogoHeader } from 'components'
import { Card } from 'components'
import { useAuth } from 'hooks'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { LoginType } from 'types'

import fields from './fields'

const Login: React.FC = () => {
  const { login } = useAuth()

  const [isInvalid, setIsInvalid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleLogin = async (data: LoginType) => {
    try {
      setIsSubmitting(true)
      const response = await client.login(data)

      if ('error' in response) {
        setIsInvalid(true)
        throw response
      }

      login(response.accessToken, response.user)
      toast.success('Login successful!', { className: 'login__toast_success' })
    } catch (error: any) {
      error.handled ||
        toast.error(error.message, {
          className: 'login__toast_error',
        })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Card
      header={<LogoHeader subText={'Please log in to continue'} />}
      footer={<Link to={'/forgot_password'} text={'Forgotten password?'} />}
      width={'100%'}
    >
      <FormWrapper
        onSubmit={handleLogin}
        wasInvalidSubmission={isInvalid}
        setWasInvalidSubmission={setIsInvalid}
        isSubmitting={isSubmitting}
        submitButtonProps={{
          text: isSubmitting ? 'Logging in ... ' : 'Login',
          width: '100%',
        }}
        fields={fields}
      />
    </Card>
  )
}

export default Login
