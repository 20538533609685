import { Box, BoxProps } from '@chakra-ui/react'
import { Text } from 'components'

const Cross: React.FC<BoxProps> = (props) => {
  return (
    <Box
      bg={'red.500'}
      w={'8'}
      h={'8'}
      rounded={'md'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      {...props}
    >
      <Text fontWeight={'bold'} color={'white'}>
        &times;
      </Text>
    </Box>
  )
}

export default Cross
