/**
 * A wrapper around Chakra-UI's Select component. This automatically wraps the
 * input in a <FormControl> and adds a label if one is passed through as props
 *
 * Some decisions have been made around defaults, including:
 *   - making `FormControl` required
 */

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps,
} from '@chakra-ui/react'
import { Text } from 'components'
import React from 'react'

type Props = SelectProps & {
  formControlProps?: FormControlProps
  label?: string
  errorMessage?: string
  children: React.ReactElement<any, any>[]
}

const Select: React.FC<Props> = ({
  id,
  label,
  formControlProps,
  errorMessage,
  children,
  ...props
}) => {
  return (
    <FormControl isRequired {...formControlProps}>
      {label ? (
        <FormLabel htmlFor={id}>
          <Text display={'inline'} type={'bold'}>
            {label}
          </Text>
        </FormLabel>
      ) : null}
      <ChakraSelect id={id} name={id} {...props}>
        {children}
      </ChakraSelect>

      {id === 'organisation_id' ? (
        <Text mt={2}>
          {"Can't find your organisation? Add it in the Organisations tab."}
        </Text>
      ) : null}

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default Select
