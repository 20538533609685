import { utils, writeFile } from 'xlsx'

// TODO: COMMENT AND FIX any
const exportUsersFromWorkshop = (data: any, filename: string) => {
  // Convert data to human readable
  const parsedData = data.map((user: any) => {
    const { address } = user

    return {
      'First Name': user.first_name,
      'Last Name': user.last_name,
      Organisation: user.organisation_name,
      Email: user.email,
      'Street Address': address?.street_address || null,
      Suburb: address?.suburb || null,
      State: address?.state || null,
      Postcode: address?.postcode || null,
      Country: address?.country || null,
      'Pre Reflection': user.pre_responses.join(', '),
      'Post Reflection': user.post_responses.join(', '),
    }
  })

  // Create new workbook
  const wb = utils.book_new()

  // Create Worksheet from data
  const ws = utils.json_to_sheet(parsedData)

  // Set column widths
  var wscols = [
    { wch: 10 },
    { wch: 10 },
    { wch: 20 },
    { wch: 25 },
    { wch: 25 },
    { wch: 15 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 25 },
    { wch: 25 },
  ]
  ws['!cols'] = wscols

  // Append sheet
  utils.book_append_sheet(wb, ws, 'Sheet1')

  // Write file
  writeFile(wb, filename)
}

export { exportUsersFromWorkshop }
