import { FormWrapper } from 'components'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { Indexable, NewQuestionObj, QuestionObj } from 'types'

import fields from './fields'

type Props = {
  moduleId: string
  onSuccessfulCreate: (question: QuestionObj) => void
  onSuccessfulDelete?: (id: string) => void
  editing?: boolean
  question?: QuestionObj | null
}

type FormData = Indexable & NewQuestionObj

const Question: React.FC<Props> = ({
  moduleId,
  onSuccessfulCreate,
  editing,
  question,
  ...rest
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (formData: FormData) => {
    try {
      setIsSubmitting(true)

      const data = { ...formData, module_id: moduleId }
      const response = editing
        ? await client.updateQuestion(formData.id, data)
        : await client.postQuestion(data)

      if ('error' in response) {
        throw response
      }

      toast.success(`Question ${editing ? 'updated' : 'created'} successfully!`)
      onSuccessfulCreate(response.question)
    } catch (error: any) {
      error.handled ||
        toast.error(
          error?.message || 'Something went wrong! Please try again later.',
        )
    } finally {
      setIsSubmitting(false)
    }
  }

  /* Return */

  return (
    <>
      <FormWrapper
        apiDeleteFunction={client.destroyQuestion}
        onSubmit={onSubmit}
        data={question}
        editing={editing}
        isSubmitting={isSubmitting}
        fields={[
          editing
            ? {
                id: 'question_order',
                label: 'Order',
                type: 'number',
                placeholder: 'Question order',
                max: 20,
                min: 1,
              }
            : {},
          ...fields,
        ]}
        {...rest}
      />
    </>
  )
}

export default Question
