import { InputProps } from 'components/UI/Input/Input'

const fields = [
  { id: 'first_name', label: 'First Name', placeholder: 'First Name' },
  { id: 'last_name', label: 'Last Name', placeholder: 'Last Name' },
  {
    id: 'email',
    label: 'Email',
    placeholder: 'user@email.com',
    type: 'email',
  },
  { id: 'role', label: 'Role', type: 'select' },
  { id: 'organisation_id', label: 'Organisation', type: 'select' },
] as InputProps[]

export default fields
