import { useEffect, useState } from 'react'
import { DeviceType } from 'types'
import { GET_DEVICE_TYPE } from 'utils'

interface WindowSize {
  width: number | undefined
  height: number | undefined
}

// Hook
const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })

  const [deviceType, setDeviceType] = useState<DeviceType>()

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })

      // Set device type
      setDeviceType(GET_DEVICE_TYPE(window.innerWidth))
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return { windowSize, deviceType }
}

export { useWindowSize }
