import {
  Card as ChakraCard,
  CardBody,
  CardFooter,
  CardFooterProps,
  CardHeader,
  CardProps,
} from '@chakra-ui/react'
import { Heading } from 'components'
import React from 'react'

type Props = CardProps & {
  header?: string | React.ReactElement<any, any>
  footer?: string | React.ReactElement<any, any> | null
  footerProps?: CardFooterProps
  children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>
}

const Card: React.FC<Props> = ({
  header,
  footer,
  footerProps = {},
  children,
  ...props
}) => {
  return (
    <ChakraCard shadow={'lg'} {...props}>
      {header ? (
        <CardHeader pb={'1'}>
          {typeof header === 'string' ? (
            <Heading size={'md'}>{header}</Heading>
          ) : (
            header
          )}
        </CardHeader>
      ) : null}

      <CardBody>{children}</CardBody>

      {footer ? (
        <CardFooter justifyContent={'center'} pt={0} {...footerProps}>
          {footer}
        </CardFooter>
      ) : null}
    </ChakraCard>
  )
}

export default Card
