export const isValidEmail = (email: string): boolean =>
  // Not too aggressive, accepts anything that looks like email
  // i.e. a@b.c  see https://stackoverflow.com/a/9204568/2716029
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

// More strict version
// export const isValidPassword = (password: string): boolean =>
//   // At least 8 characters, 1 uppercase, 1 lowercase, 1 number
//   // see https://stackoverflow.com/a/21456918/2716029
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(password)

export const isValidPassword = (password: string): boolean =>
  // At least 8 characters
  /^.{8,}$/.test(password)
