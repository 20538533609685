import { ExternalLink, Text } from 'components'
import { WorkshopObj } from 'types'
import { formatDate } from 'utils'

interface Props {
  workshop: WorkshopObj
}

const NoActionRequired: React.FC<Props> = ({ workshop }) => {
  const TextProps = {
    display: 'inline',
  }

  return (
    <>
      <Text {...TextProps}>
        {`You have been enrolled in the ${workshop.module_name} module of the
        Being Leaders. Session 1 will start on`}
      </Text>
      <Text type={'bold'} {...TextProps}>
        {` ${formatDate(workshop.session_1)}`}
      </Text>
      <Text {...TextProps}>
        {
          '. We will be in contact closer to the date with more information. In the meantime, check out'
        }
      </Text>
      <ExternalLink
        href={process.env.REACT_APP_BL_WEBSITE as string}
        {...TextProps}
      >
        {' our site'}
      </ExternalLink>
      <Text {...TextProps}>{' or our'}</Text>
      <ExternalLink
        href={process.env.REACT_APP_BL_LINKEDIN as string}
        {...TextProps}
      >
        {' LinkedIn'}
      </ExternalLink>
      <Text {...TextProps}>{' for more information about who we are.'}</Text>
    </>
  )
}

export default NoActionRequired
