import { Box, Flex } from '@chakra-ui/react'
import { Button, Heading, Text } from 'components'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { QuestionObj } from 'types'
import { times } from 'utils'

interface Props {
  question: QuestionObj
  questionNumber: number
  handleRatingClick: (rating: string) => void
  alreadySelectedRating: string | undefined
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const Question: React.FC<Props> = ({
  question,
  questionNumber,
  handleRatingClick,
  alreadySelectedRating,
}) => {
  const [[page, direction], setPage] = useState([0, 0])

  useEffect(() => {
    if (page === questionNumber) return

    setPage([questionNumber, questionNumber > page ? 1 : -1])
  }, [questionNumber, page])

  const rating = parseInt(process.env.REACT_APP_ASSESSMENT_SCALE || '7')
  const ratingsArr = times(rating)

  return (
    <motion.div
      key={page}
      variants={variants}
      custom={direction}
      exit={'exit'}
      animate={'center'}
      initial={'enter'}
      transition={{
        x: { type: 'spring', stiffness: 300, damping: 30, duration: 0.7 },
        opacity: { duration: 0.9 },
      }}
    >
      <Text>{question.question}</Text>

      <Flex my={'3'} flexDirection={'row'} justifyContent={'center'}>
        {ratingsArr.map((r, index) => (
          <Box mr={index === rating - 1 ? '0' : '2'} key={`rating${r}`}>
            <Button
              onClick={() => handleRatingClick(r.toString())}
              colorScheme={
                alreadySelectedRating === r.toString() ? 'brand' : 'gray'
              }
              size={'sm'}
            >
              <Heading size={'sm'}>{`${r}`}</Heading>
            </Button>
          </Box>
        ))}
      </Flex>

      <Flex flexWrap={'nowrap'} justifyContent={'space-around'} mb={2}>
        <Text>{'0 = Never'}</Text>
        <Text>{`${rating} = Always`}</Text>
      </Flex>
    </motion.div>
  )
}

export default Question
