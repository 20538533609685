/**
 * This page does not serve much purpose, in fact we could just go to
 * <Assessment type={'post'} /> directly from AppRouter, but we will keep
 * it this way, in case we need to add unique features for post assessment
 * in the future
 */

import { UserQuestions } from 'components'
import React from 'react'

const PostWorkshopQuestions: React.FC = () => {
  return <UserQuestions response_type={'post'} />
}

export default PostWorkshopQuestions
