import { Box } from '@chakra-ui/react'
import {
  AddressForm,
  Card,
  CenteredContainer,
  Dashboard,
  ExternalLink,
  Login,
  LogoHeader,
  Text,
} from 'components'
import { useAuth } from 'hooks'
import { AdminDashboard } from 'pages'
import React from 'react'
import { toast } from 'react-toastify'
import { AddressObj } from 'types'

const Home: React.FC = () => {
  const { user, updateUser } = useAuth()

  const handleAddressSubmit = (address: AddressObj) => {
    toast.success('Registration complete!')
    updateUser({ ...user, address })
  }

  if (user?.role === 'admin') return <AdminDashboard />

  if (user && (user.address || user.hasAddress)) return <Dashboard />

  const TextProps = {
    fontSize: 'sm',
    display: 'inline',
  }

  return (
    <CenteredContainer>
      {user ? (
        <Card
          header={
            <LogoHeader
              subText={'Please enter your address to complete registration'}
            />
          }
        >
          <Box mb={4}>
            <Text {...TextProps}>
              {`Why do we need you address? Your registration in the Being Leaders Program
              entitles you to a free copy of the Amazon Best seller - `}
            </Text>
            <ExternalLink
              href={process.env.REACT_APP_AMAZON_BOOK_LINK as string}
              {...TextProps}
            >
              {'Becoming a Leader of Leaders'}
            </ExternalLink>
            <Text {...TextProps}>
              {`. The physical copy of this book will be sent to the address you enter below - so make
              sure to triple check your details! Interested in how we manage your data; check out our `}
            </Text>
            <ExternalLink
              href={process.env.REACT_APP_BL_PRIVACY_POLICY as string}
              {...TextProps}
            >
              {'privacy policy'}
            </ExternalLink>
          </Box>

          <AddressForm
            id={user.id || ''}
            onSubmitSuccess={handleAddressSubmit}
          />
        </Card>
      ) : (
        <Login />
      )}
    </CenteredContainer>
  )
}

export default Home
