import { api } from 'services'
import { FetchResponse, ModuleObj, NewModuleObj, QueryOptions } from 'types'

const getModules = async (
  options: QueryOptions = {},
): Promise<
  FetchResponse<{
    modules: ModuleObj[]
  }>
> => {
  return api.get('/admin/modules', {}, options)
}

const postModule = async (
  module: NewModuleObj,
): Promise<FetchResponse<{ module: ModuleObj }>> => {
  return api.post('/admin/modules', { module: module })
}

const updateModule = async (
  id: string,
  obj: NewModuleObj,
): Promise<FetchResponse<{ module: ModuleObj }>> => {
  return api.patch(`/admin/modules/${id}`, {
    module: obj,
  })
}

const destroyModule = async (id: string): Promise<FetchResponse<{}>> => {
  return api.delete(`/admin/modules/${id}`)
}

const modules = {
  getModules,
  postModule,
  updateModule,
  destroyModule,
}

export default modules
