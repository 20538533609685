/**
 * Currently, this component does not do much. It merely wraps the Chakra-UI
 * button, without adding extra functionality, but, in future, can be set up
 * to make buttons more consistent (i.e. have a danger button, a success
 * button, etc)
 */

import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  { children, ...rest },
  ref,
) => {
  return (
    <ChakraButton ref={ref} colorScheme={'brand'} {...rest}>
      {children}
    </ChakraButton>
  )
}

export default forwardRef(Button)
