import { FormWrapper } from 'components'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { Indexable, ModuleObj, NewModuleObj } from 'types'

import fields from './fields'

type Props = {
  onSuccessfulCreate: (module: ModuleObj) => void
  onSuccessfulDelete?: (id: string) => void
  editing?: boolean
  module?: ModuleObj | null
}

type FormData = Indexable & NewModuleObj

const Module: React.FC<Props> = ({
  onSuccessfulCreate,
  editing,
  module,
  ...rest
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (data: FormData) => {
    try {
      setIsSubmitting(true)

      const response = editing
        ? await client.updateModule(data.id, data)
        : await client.postModule(data)

      if ('error' in response) {
        throw response
      }

      toast.success(
        editing ? 'Module created updated!' : 'Module created successfully!',
      )
      onSuccessfulCreate(response.module)
    } catch (error: any) {
      error.handled ||
        toast.error(
          error?.message || 'Something went wrong! Please try again later.',
        )
    } finally {
      setIsSubmitting(false)
    }
  }

  /* Return */

  return (
    <FormWrapper
      apiDeleteFunction={client.destroyModule}
      onSubmit={onSubmit}
      data={module}
      fields={fields}
      editing={editing}
      isSubmitting={isSubmitting}
      {...rest}
    />
  )
}

export default Module
