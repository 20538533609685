import { Flex } from '@chakra-ui/react'
import {
  AdminTable,
  Button,
  EmailCommunication,
  Modal,
  SubHeading,
  Text,
  UserImport,
  UserPopup,
  WorkshopForm,
} from 'components'
import { useAlert } from 'hooks'
import React, { useCallback, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from 'services'
import {
  ImportUsersType,
  Indexable,
  QueryOptions,
  UsersWorkshopsStatus,
  WorkshopObj,
  WorkshopObjWithUsersAndOrganisation,
  WorkshopUserObj,
} from 'types'
import { exportUsersFromWorkshop, formatStatus } from 'utils'

type ModalType = 'addUser' | 'updateWorkshop' | 'exportUsers' | ''

const Workshop: React.FC = () => {
  const params = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { onOpen, onClose } = useAlert()

  const [workshop, setWorkshop] = useState<
    WorkshopObjWithUsersAndOrganisation & Indexable
  >({} as WorkshopObjWithUsersAndOrganisation & Indexable)
  const [displayImportUsers, setDisplayImportUsers] = useState<boolean>(false)

  const [modalType, setModalType] = useState<ModalType>('')

  // Helper function to display the status of the user
  const displayStatus = useCallback(
    (
      userId: string,
      status: UsersWorkshopsStatus,
      existingWorkshop: {
        id?: string
        pre_workshop_available?: boolean
        post_workshop_available?: boolean
      } = {},
    ) => {
      const { id: workshopId } = existingWorkshop
      return {
        displayStatus: formatStatus({
          status: status,
          id: userId,
          workshopId: workshopId || workshop?.id || '',
          onSuccess: handleInviteSuccessRef.current,
          delivery: workshop.delivery,
          // ...existingWorkshop,
        }),
      }
    },
    [workshop?.id, workshop.delivery],
  )

  const handleInviteSuccess = useCallback(
    (
      data: WorkshopObj | WorkshopObjWithUsersAndOrganisation | {},
      options: {
        updateUser?: boolean
        userId?: string
        status?: UsersWorkshopsStatus
      } = {},
    ) => {
      const castData = data as WorkshopObjWithUsersAndOrganisation

      if (options.updateUser) {
        return setWorkshop((prev) => ({
          ...prev,
          ...castData,
          users: (castData.users || prev?.users || []).map((u) =>
            u?.id?.toString() === options.userId
              ? {
                  ...u,
                  status: options.status || u.status,
                  ...displayStatus(
                    u.id || '',
                    options.status || u.status,
                    prev,
                  ),
                }
              : {
                  ...u,
                  ...displayStatus(u.id || '', u.status, prev),
                },
          ),
        }))
      }

      setWorkshop((prev) => ({ ...prev, ...castData }))
    },
    [displayStatus],
  )

  const handleInviteSuccessRef = useRef(handleInviteSuccess)

  const setData = useCallback(
    (data: WorkshopObjWithUsersAndOrganisation) => {
      setWorkshop({
        ...data,
        users: (data.users || []).map((u) => ({
          ...u,
          ...displayStatus(u.id || '', u.status, {
            id: data.id,
            pre_workshop_available: data.pre_workshop_available,
            post_workshop_available: data.post_workshop_available,
          }),
        })),
      })
    },
    [displayStatus],
  )

  const fetchData = useCallback(
    async (options: QueryOptions) => {
      return await client.getWorkshop(params.id || '', options)
    },
    [params.id],
  )

  const handleDeleteConfirm = async () => {
    try {
      await client.destroyWorkshop(workshop?.id || '')
      onClose()
      toast.success('Workshop successfully deleted!')
      navigate('/admin/workshops')
    } catch (error) {
      console.log('admin/Workshop::handleDeleteConfirm() - Error: ', error)
      toast.error('Error deleting workshop')
    }
  }

  const handleUpdate = async (data: WorkshopObj) => {
    const castData = data as WorkshopObjWithUsersAndOrganisation
    setWorkshop({ ...workshop, ...castData })
    setModalType('')
  }

  const handleImportSuccess = (data: ImportUsersType['created']) => {
    const castData = data as WorkshopUserObj[]
    const existingUserIds = new Set((workshop?.users || []).map((u) => u.id))
    const newUserIds = new Set(castData.map((u) => u.id))

    const users = [
      ...(workshop?.users || []).map((u) => {
        let newUser = newUserIds.has(u.id)
          ? castData.find((d) => d.id === u.id)
          : u
        if (newUser && !('displayStatus' in newUser)) {
          newUser = {
            ...newUser,
            ...displayStatus(newUser.id || '', newUser.status),
          }
        }
        return newUser
      }),
      ...castData
        .filter((d) => !existingUserIds.has(d.id))
        .map((d) => ({ ...d, ...displayStatus(d.id || '', d.status) })),
    ] as WorkshopUserObj[]

    setWorkshop((prev) => ({
      ...prev,
      users: users,
    }))

    toast.success('Users successfully imported to workshop!')
  }

  const handleUserSelection = (user: WorkshopUserObj) => {
    setWorkshop((prev) => ({
      ...prev,
      users: [
        ...prev.users,
        { ...user, ...displayStatus(user.id || '', user.status) },
      ],
    }))
  }

  const handleRowClick = (index: number) => {
    navigate(`/admin/users/${workshop?.users[index].id}`)
  }

  const renderModal = () => {
    if (!modalType) return null
    const obj = MODALS[modalType]
    const { children, ...rest } = obj

    return (
      <Modal isOpen={!!modalType} onClose={() => setModalType('')} {...rest}>
        {children}
      </Modal>
    )
  }

  const removeUserFromWorkshopSubmit = async (userId: string) => {
    try {
      const response = await client.removeUserFromWorkshop({
        userId: userId,
        workshopId: workshop.id,
      })
      if ('error' in response) {
        return console.log('TODO: HANDLE ERROR: ', response)
      }

      setWorkshop((prev) => ({
        ...prev,
        users: prev.users.filter((u) => u.id !== userId),
      }))
      toast.success('User removed from workshop successfully!')
    } catch (error) {
      console.log(
        'admin/Workshop::removeUserFromWorkshopSubmit() - Error: ',
        error,
      )
      toast.error('Error removing user from workshop')
    }
  }

  const handleUserExport = async () => {
    try {
      const response = await client.exportWorkshopUsers(workshop.id)
      if ('error' in response) {
        return console.log('TODO: HANDLE ERROR: ', response)
      }

      exportUsersFromWorkshop(
        response,
        `${workshop.module_name || 'export'}.xlsx`,
      )

      setModalType('')
      toast.success('Users exported successfully!')
    } catch (error) {
      console.log('admin/Workshop::handleUserExport() - Error: ', error)
      toast.error('Error exporting users')
    }
  }

  const MODALS = {
    addUser: {
      title: '',
      hideButtons: true,
      modalSize: {
        size: '6xl',
      },
      children: (
        <UserPopup
          organisationId={workshop?.organisation_id || ''}
          handleUserSelection={handleUserSelection}
          usersInWorkshop={workshop?.users || []}
          workshopId={workshop?.id || ''}
        />
      ),
    },
    updateWorkshop: {
      title: 'Update Workshop',
      hideButtons: true,
      children: (
        <WorkshopForm
          onSuccessfulCreate={handleUpdate}
          onSuccessfulDelete={handleDeleteConfirm}
          workshop={workshop}
          editing={true}
        />
      ),
    },
    exportUsers: {
      title: 'Export Users',
      handleSubmit: handleUserExport,
      confirmText: 'Export',
      children: (
        <Text>
          {`Exporting users will give you the basic info for a user,
          their address and any of their responses for this workshop`}
        </Text>
      ),
    },
  }

  const BUTTONS = [
    {
      text: 'Export Users',
      onClick: () => setModalType('exportUsers'),
      color: 'yellow',
    },
    {
      text: 'Delete Workshop',
      onClick: () => onOpen(handleDeleteConfirm),
      color: 'red',
    },
    {
      text: 'Update Workshop',
      onClick: () => setModalType('updateWorkshop'),
      color: 'blue',
    },
    {
      text: 'Import Users to Workshop',
      onClick: () => setDisplayImportUsers(true),
      color: 'blue',
    },
    {
      text: 'Add Users to Workshop',
      onClick: () => setModalType('addUser'),
      color: 'green',
    },
  ]

  return (
    <>
      <AdminTable
        heading={workshop.module_name || ''}
        SubHeading={<SubHeading workshop={workshop} />}
        data={workshop.users}
        initialDataFetch={fetchData}
        type={'workshop'}
        setParentData={setData}
        tableCaption={'Click on a row to view user details'}
        onRowClick={handleRowClick}
        removeUserFromWorkshopSubmit={removeUserFromWorkshopSubmit}
      />

      <Flex flexWrap={'wrap'} justifyContent={'flex-end'} mt={'3'}>
        <EmailCommunication
          workshop={workshop}
          onSuccess={handleInviteSuccessRef.current}
        />
        {BUTTONS.map((b, i) => (
          <Button
            key={`${b.text}${i}`}
            mr={'2'}
            mb={'2'}
            onClick={b.onClick}
            colorScheme={b.color}
          >
            {b.text}
          </Button>
        ))}
      </Flex>

      {workshop ? (
        <UserImport
          isOpen={displayImportUsers}
          onClose={() => setDisplayImportUsers(false)}
          onSuccess={handleImportSuccess}
          workshop={workshop}
        />
      ) : null}

      {modalType ? renderModal() : null}
    </>
  )
}

export default Workshop
