import { Box } from '@chakra-ui/react'
import { AdminTable, Modal, Text } from 'components'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { QueryOptions, UserObj, WorkshopUserObj } from 'types'

interface Props {
  organisationId: string
  handleUserSelection: (user: WorkshopUserObj) => void
  usersInWorkshop: UserObj[]
  workshopId: string
}

const UserPopup: React.FC<Props> = ({
  organisationId,
  handleUserSelection,
  usersInWorkshop,
  workshopId,
}) => {
  const [users, setUsers] = useState<UserObj[]>([])
  const [selectedUser, setSelectedUser] = useState<UserObj | null>(null)

  const fetchUsers = useCallback(
    async (options: QueryOptions & Props) => {
      const obj = organisationId ? { organisationId } : {}
      return await client.getUsers({ ...options, ...obj })
    },
    [organisationId],
  )

  const setData = useCallback(
    (data: UserObj[]) => {
      const usersToRemove = new Set(usersInWorkshop.map((u) => u.id))
      const filteredUsers = data ? data.filter((user) => !usersToRemove.has(user.id)) : []

      setUsers(filteredUsers)
    },
    [usersInWorkshop],
  )

  const handleRowClick = (index: number) => {
    setSelectedUser(users[index])
  }

  const confirmSelection = async (user: UserObj) => {
    const response = await client.addUserToWorkshop(
      user.id as string,
      workshopId as string,
    )

    if ('error' in response) {
      return console.log('TODO: HANDLE ERROR: ', response)
    }

    toast.success('User successfully added to workshop!')
    setSelectedUser(null)
    setUsers(users.filter((u) => u.id !== user.id))
    handleUserSelection({ ...user, status: response.user.status })
  }

  if (!users) return null

  return (
    <Box width={'100%'}>
      <AdminTable
        heading={'Add User to Workshop'}
        data={users}
        initialDataFetch={fetchUsers}
        type={'users'}
        onRowClick={handleRowClick}
        setParentData={setData}
        tableCaption={'Click on a row to add that user to the workshop'}
        noDataMessage={'All users from organisation are already in workshop'}
      />

      <Modal
        isOpen={!!selectedUser}
        title={'Add User to Workshop'}
        onClose={() => setSelectedUser(null)}
        confirmText={'Add User'}
        handleSubmit={() => confirmSelection(selectedUser as UserObj)}
      >
        <Text>{`Are you sure you want to add ${selectedUser?.first_name} to the workshop?`}</Text>
      </Modal>
    </Box>
  )
}

export default UserPopup
