/**
 * Create an array from a number or the string of a number.
 * It will default to creating an array of 1..number, but a custom
 * function can be passed through using `fn` to create a custom array
 */
const times = (n: number | string, fn?: (v: unknown, k: number) => number) => {
  return Array.from(
    { length: typeof n === 'string' ? parseInt(n) : n },
    (v, i) => (fn ? fn(v, i) : i + 1),
  )
}

/**
 * Capitalise the first letter of each word, ensuring every other letter is lower case
 */
const capitliseWord = (str: string) => {
  return str
    .toLocaleLowerCase()
    .replace(/\b\w/g, (match) => match.toUpperCase())
}

export { capitliseWord, times }
