/**
 * Wrapper around Chakra-UI Modal component.
 *
 * Buttons:
 * We have the options of passing the prop hideButtons, which will hide the
 * all the Modal buttons. We also have the option of passing a handle
 * function, for when the `submit` button is pressed
 */

import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react'
import { Button, Text } from 'components'

type Props = ModalContentProps & {
  title: string
  isOpen: boolean
  onClose: () => void
  modalSize?: ModalProps['size']
  text?: string
  children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>
  hideButtons?: boolean
  handleSubmit?: (() => void) | null
  confirmText?: string
  submitLoadingText?: string
}

const Modal: React.FC<Props> = ({
  title,
  children,
  isOpen,
  onClose,
  text,
  hideButtons = false,
  handleSubmit,
  confirmText,
  submitLoadingText,
  modalSize,
  ...modalContentProps
}) => {
  return (
    <ChakraModal size={modalSize} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent marginx={10} {...modalContentProps}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{text ? <Text>{text}</Text> : children}</ModalBody>

        {hideButtons || !handleSubmit ? null : (
          <ModalFooter>
            <Button variant={'ghost'} mr={3} onClick={onClose}>
              <Text>{'Close'}</Text>
            </Button>
            <Button
              isDisabled={!!submitLoadingText}
              onClick={handleSubmit}
              colorScheme={'blue'}
            >
              {submitLoadingText || confirmText || 'Submit'}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
