import { useDisclosure } from '@chakra-ui/react'
import { AdminTable, Button, ModuleForm } from 'components'
import { Modal } from 'components'
import { useOrganisation } from 'hooks'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { client } from 'services'
import { ModuleObj } from 'types'

const NavToQuestions: React.FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate()
  return (
    <Button onClick={() => navigate(`/admin/reflection-questions/${id}`)}>
      {'Questions'}
    </Button>
  )
}

const Modules: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { updateModule, deleteModule } = useOrganisation()

  const [modules, setModules] = useState<ModuleObj[]>([])

  const [selectedModule, setSelectedModule] = useState<ModuleObj | null>(null)

  // Set module data with link to questions
  const setData = useCallback((data: ModuleObj[]) => {
    if (!data) return setModules(data)

    setModules(
      data.map((d) => ({
        ...d,
        questions: <NavToQuestions id={d.id} />,
      })),
    )
  }, [])

  const onSuccessfulCreate = async (data: any) => {
    updateModule(data)

    // Set new modules, by placing the new / edited module in the correct place,
    // and sorting the array alphabetically
    const newModules = !!selectedModule
      ? (modules || []).map((d) => (d.id === data.id ? data : d))
      : [...(modules || []), data]
    setModules(
      newModules
        .map((d) => ({
          ...d,
          questions: <NavToQuestions id={d.id} />,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    )

    setSelectedModule(null)
    onClose()
  }

  const onSuccessfulDelete = async (id: string) => {
    deleteModule(id)
    setModules((prev) => (prev || []).filter((module) => module.id !== id))

    setSelectedModule(null)
    onClose()
  }

  const handleRowClick = (index: number) => {
    setSelectedModule(modules[index])
    onOpen()
  }

  return (
    <>
      <AdminTable
        heading={'Modules'}
        data={modules}
        initialDataFetch={client.getModules}
        type={'modules'}
        onRowClick={handleRowClick}
        setParentData={setData}
        onButtonClick={onOpen}
      />
      <Modal
        title={!!selectedModule ? 'Module' : 'Create New Module'}
        isOpen={isOpen}
        onClose={() => {
          setSelectedModule(null)
          onClose()
        }}
        hideButtons={true}
      >
        <ModuleForm
          onSuccessfulCreate={onSuccessfulCreate}
          onSuccessfulDelete={onSuccessfulDelete}
          module={selectedModule}
          editing={!!selectedModule}
        />
      </Modal>
    </>
  )
}

export default Modules
