import { TextProps } from '@chakra-ui/react'
import { Text } from 'components'
import { Link as ReactLink, LinkProps } from 'react-router-dom'

interface Props extends LinkProps {
  text: string
  TextProps?: TextProps
}

const Link: React.FC<Props> = ({ to, text, TextProps, ...props }) => {
  const id =
    (to as string).replaceAll('/', '') ||
    text.toLocaleLowerCase().replaceAll(' ', '_')

  return (
    <ReactLink id={id} to={to} {...props}>
      <Text type={'link'} {...TextProps}>
        {text}
      </Text>
    </ReactLink>
  )
}

export default Link
