import { Flex } from '@chakra-ui/react'
import { Text } from 'components'
import logo from 'images/beingLeadersLogoTransparent.png'

interface Props {
  subText?: string
}

const LogoHeader: React.FC<Props> = ({ subText }) => {
  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
      <img src={logo} alt={'logo'} width={'40%'} />

      {subText ? (
        <Text type={'bold'} mt={'5'}>
          {subText}
        </Text>
      ) : null}
    </Flex>
  )
}

export default LogoHeader
