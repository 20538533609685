import { TextProps } from '@chakra-ui/react'
import { Text } from 'components'

interface Props extends TextProps {
  href: string
  children: string | React.ReactElement<any, any>
  target?: string
}

const ExternalLink: React.FC<Props> = ({
  href,
  target,
  children,
  ...props
}) => {
  return (
    <a href={href} rel={'noreferrer'} target={target || '_blank'}>
      <Text type={'link'} {...props}>
        {children}
      </Text>
    </a>
  )
}

export default ExternalLink
