import { useAuth } from 'hooks'
import React from 'react'
import { Navigate } from 'react-router-dom'

type Props = {
  redirectPath?: string
  children: React.ReactElement<any, any>
}

const AdminRoute: React.FC<Props> = ({ redirectPath = '/', children }) => {
  const { user } = useAuth()

  if (!user || user.role !== 'admin') {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

export default AdminRoute
