import { Navbar, ProtectedRoute, UnauthorisedRoute } from 'components'
import { Loading } from 'components'
import { AdminRoute } from 'components'
import { useAuth } from 'hooks'
import {
  ForgotPassword,
  Home,
  PostWorkshopQuestions,
  PreWorkshopQuestions,
  ResetPassword,
  User,
  UserAccount,
} from 'pages'
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import adminRoutes from './adminRoutes'

const AppRouter: React.FC = () => {
  const { isLoading } = useAuth()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Router>
      <Navbar />

      <Routes>
        <Route index element={<Home />} />

        {/* Auth routes */}

        <Route
          path={'/reset_password/:token'}
          element={
            <UnauthorisedRoute>
              <ResetPassword />
            </UnauthorisedRoute>
          }
        />

        <Route path={'/forgot_password'} element={<ForgotPassword />} />

        <Route path={'/invite/:token'} element={<User />} />

        {/* User Routes */}

        <Route
          path={'/account'}
          element={
            <ProtectedRoute>
              <UserAccount />
            </ProtectedRoute>
          }
        />

        {/* User Workshop Routes */}

        {/*
          Commenting out the `show` workshopp route for a user, as functionality is
          not required for now.
        */}
        {/* <Route
          path={'/user/workshop/:id'}
          element={
            <ProtectedRoute>
              <UserWorkshop />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path={'/user/workshop/:id/pre'}
          element={
            <ProtectedRoute>
              <PreWorkshopQuestions />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/user/workshop/:id/post'}
          element={
            <ProtectedRoute>
              <PostWorkshopQuestions />
            </ProtectedRoute>
          }
        />

        {/* Admin Only Routes */}

        {adminRoutes.map((route, index) => (
          <Route
            key={`${route.path}${index}`}
            path={route.path}
            element={
              <AdminRoute>
                <route.element />
              </AdminRoute>
            }
          />
        ))}

        <Route path="*" element={<p>TODO: CREATE 404!</p>} />
      </Routes>
    </Router>
  )
}

export default AppRouter
