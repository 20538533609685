/**
 * This alert is available via the useAlert hook.
 * Currently, it only acts a delete confirmation, but this can be extended to
 */

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { Button } from 'components'
import { useRef } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: null | (() => {})
}

const Alert: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const cancelRef = useRef(null)

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{'Delete Item'}</AlertDialogHeader>
        <AlertDialogBody>
          {`Are you sure you want to delete this item? You can't undo this action
          afterwards.`}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button
            onClick={() => onConfirm && onConfirm()}
            colorScheme={'red'}
            ml={3}
          >
            {'Delete'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default Alert
