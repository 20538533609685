import { ExternalLink, Text } from 'components'
import { WorkshopObj } from 'types'

interface Props {
  workshop: WorkshopObj
}

const PreWorkshopCompleted: React.FC<Props> = () => {
  const TextProps = {
    display: 'inline',
  }

  return (
    <>
      <Text {...TextProps}>
        {'Thank you for completing your Pre Program Self Reflection.'}{' '}
        {'There are no actions currently required.'}{' '}
        {'In the meantime, stay up to date with the latest insights on our'}
      </Text>
      <ExternalLink
        href={process.env.REACT_APP_BL_LINKEDIN as string}
        {...TextProps}
      >
        {' LinkedIn'}
      </ExternalLink>
    </>
  )
}

export default PreWorkshopCompleted
