import { Password } from 'components'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from 'services'

const ResetPassword: React.FC = () => {
  const navigate = useNavigate()

  const params = useParams<{ token: string }>()
  const { token = '' } = params

  useEffect(() => {
    const fetchUserId = async () => {
      const response = await client.getResetPassword(token)

      if ('error' in response || !response.userId) {
        toast.error('Invalid or expired link. Please try again.', {
          className: 'resetPassword__error',
        })
        return navigate('/')
      }
    }

    fetchUserId()
  }, [navigate, token])

  return <Password token={token} type={'reset'} />
}

export default ResetPassword
