import { SendInvite, Text } from 'components'
import DOMPurify from 'dompurify'
import { variables } from 'styles'
import {
  NewWorkshopObj,
  OrganisationObj,
  UsersWorkshopsStatus,
  WorkshopDelivery,
  WorkshopObj,
  WorkshopObjWithUsersAndOrganisation,
} from 'types'

type dateFormat =
  | 'DD/MM/YYYY'
  | 'yyyy-MM-dd'
  | 'yyyy-mm-ddThh:mm'
  | 'HH:MM dd/mm/yyyy'

/**
 * A record on the UsersWorkshops table will recieve a status of 0, 1, 2, 3
 * or 4. That obviously doesn't mean much to the user, so we will replace
 * that number with a more meaningful string
 */
const formatStatus = ({
  status,
  pre_workshop_available,
  post_workshop_available,
  ...rest
}: WorkshopDelivery & {
  status: UsersWorkshopsStatus
  id: string
  workshopId: string
  pre_workshop_available?: boolean
  post_workshop_available?: boolean
  onSuccess: (
    data: WorkshopObj | WorkshopObjWithUsersAndOrganisation | {},
  ) => void
}) => {
  switch (status) {
    case 0: // User has been created
      return <SendInvite {...rest} />
    case 1: // User has been invited
      return <SendInvite type={'reminder'} {...rest} />
    case 2: // User has received reminder
      return (
        <Text type={'bold'} color={variables.colours.orange}>
          {'Reminder sent; invite not yet accepted'}
        </Text>
      )
    case 3: // User has created password
      return (
        <Text type={'bold'} color={variables.colours.secondary.base}>
          {'Invite accepted; password created'}
        </Text>
      )
    case 4: // User has created address
      if (pre_workshop_available) {
        return (
          <Text type={'bold'} color={variables.colours.secondary.base}>
            {
              'Invite accepted; password and address created; Pre Assessment not complete'
            }
          </Text>
        )
      }

      return (
        <Text type={'bold'} color={variables.colours.green}>
          {'Invite accepted; password and address created'}
        </Text>
      )
    case 5: // User has completed pre-workshop
      if (post_workshop_available) {
        return (
          <Text type={'bold'} color={variables.colours.secondary.base}>
            {'Pre Assessment complete; Post Assessment not complete'}
          </Text>
        )
      }

      return (
        <Text type={'bold'} color={variables.colours.green}>
          {'Pre Assessment complete'}
        </Text>
      )
    case 6: // User has completed post-workshop
      return (
        <Text type={'bold'} color={variables.colours.green}>
          {'Post Assessment complete'}
        </Text>
      )
  }
}

/**
 * Pass in a date object, and return a string in the format you want
 */
const formatDate = (dateString: string, format: dateFormat = 'DD/MM/YYYY') => {
  const date = new Date(dateString)
  const year = date.getFullYear().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  switch (format) {
    case 'yyyy-MM-dd':
      return `${year}-${month}-${day}`
    case 'yyyy-mm-ddThh:mm':
      return `${year}-${month}-${day}T${hours}:${minutes}`
    case 'HH:MM dd/mm/yyyy':
      return `${hours}:${minutes} ${day}/${month}/${year}`
    default:
      return `${day}/${month}/${year}`
  }
}

/**
 * Currently set up for the following formatting:
 *   - date (by passing through dateFormat)
 *   - organisation name (by passing in organisations array)
 *   - displayStatus (by passing in displayStatus)
 */
const formatWorkshop = (
  workshop: (WorkshopObj | NewWorkshopObj) & { status?: UsersWorkshopsStatus },
  options: {
    dateFormat?: dateFormat
    organisations?: OrganisationObj[]
    formatStatus?: true
  } = {},
) => {
  const rest = {} as WorkshopObjWithUsersAndOrganisation // TODO: FIX ANY!!!
  const castWorkshop = workshop as WorkshopObj & {
    status?: UsersWorkshopsStatus
  }

  if (options.organisations) {
    rest.organisation_name = options.organisations.find(
      (o) => o.id === castWorkshop.organisation_id,
    )?.name as string
  }

  if (options.formatStatus && castWorkshop.status !== undefined) {
    // rest.status = formatStatus(castWorkshop.status)
  }

  if (castWorkshop.session_1)
    rest.session_1 = formatDate(castWorkshop.session_1, 'yyyy-mm-ddThh:mm')
  if (castWorkshop.session_2)
    rest.session_2 = formatDate(castWorkshop.session_2, 'yyyy-mm-ddThh:mm')
  if (castWorkshop.session_reflection)
    rest.session_reflection = formatDate(
      castWorkshop.session_reflection,
      'yyyy-mm-ddThh:mm',
    )
  if (castWorkshop.ftf_workshop_date)
    rest.ftf_workshop_date = formatDate(
      castWorkshop.ftf_workshop_date,
      'yyyy-MM-dd',
    )

  return {
    ...castWorkshop,
    ...rest,
  }
}

/**
 * Convert text to JSX.
 * Allows the following tags (more can be added quite simply):
 *   - <li>
 *   - <ul>
 *   - <b>
 *   - <br>
 */
const formatTextAsJSX = (text: string) => {
  const cleanedText = DOMPurify.sanitize(text, {
    ALLOWED_TAGS: ['ul', 'li', 'br', 'b'],
  })

  return <div dangerouslySetInnerHTML={{ __html: cleanedText }} />
  // const ulRegex = /<ul>(.*?)<\/ul>/g
  // const liRegex = /<li>(.*?)<\/li>/g
  // const boldRegex = /<b>(.*?)<\/b>/g

  // const replaceUL = (match: string, p1: string) => {
  //   return (<ul>{formatTextAsJSX(p1)}</ul>).toString()
  // }

  // const replaceLI = (match: string, p1: string) => {
  //   return (<li>{formatTextAsJSX(p1)}</li>).toString()
  // }

  // const replaceBold = (match: string, p1: string) => {
  //   return (<b>{p1}</b>).toString()
  // }

  // return (
  //   <React.Fragment>
  //     {text
  //       .replace(ulRegex, replaceUL)
  //       .replace(liRegex, replaceLI)
  //       .replace(boldRegex, replaceBold)}
  //   </React.Fragment>
  // )
}

export { formatDate, formatStatus, formatTextAsJSX, formatWorkshop }
