import { Flex, Heading, VStack } from '@chakra-ui/react'
import { Card, Text } from 'components'
import React from 'react'

const AdminDashboard: React.FC = () => {
  return (
    <Flex justifyContent={'center'}>
      <Card
        header={
          <Heading textAlign={'center'} size={'md'}>
            {'Admin'}
          </Heading>
        }
        width={'50%'}
      >
        <VStack
          spacing={4}
          alignItems={'flex-start'}
          // align={'stretch'}
          // templateColumns={'repeat(auto-fill, minmax(200px, 1fr))'}
        >
          <Text>
            {`Welcome to the admin section of Being Leaders. Use the navbar
            above to navigate around the site, and make the changes you need to.`}
          </Text>
          <Text>{`A reminder of the flow of creating a new workshop:`}</Text>
          <Text>
            {`1. Ensure all organisations for all workshops participants have been added`}
          </Text>
          <Text>{`2. Create a new module, if the workshop module does not exist`}</Text>
          <Text>{'3. Do the questions need to be tweaked?'}</Text>
          <Text>{'4. Create zoom / ms team links for session 1 and 2'}</Text>
          <Text>{'5. Create new workshop'}</Text>
          <Text>
            {'6. Within the new workshop, import users from spreadsheet'}
          </Text>
          <Text>{'7. Send out Welcome Email'}</Text>

          {/* <Text>{'TODO: Add notification style dashboard here'}</Text> */}
          {/* <Link to={'/admin/users'}>
              <Button colorScheme={'blue'}>{'Users'} </Button>
            </Link>
            <Link to={'/admin/organisations'}>
              <Button colorScheme={'orange'}>{'Organisations'} </Button>
            </Link>
            <Link to={'/admin/workshops'}>
              <Button colorScheme={'purple'}>{'Workshops'} </Button>
            </Link>
            <Link to={'/admin/pre-assessments'}>
              <Button colorScheme={'yellow'}>
                {'Pre Assessment Questions'}
              </Button>
            </Link>
            <Link to={'/admin/post-assessments'}>
              <Button colorScheme={'green'}>
                {'Post Assessment Questions'}
              </Button>
            </Link> */}
        </VStack>
      </Card>
    </Flex>
  )
}

export default AdminDashboard
