import emails from './emails'
import modules from './modules'
import organisations from './organisations'
import questions from './questions'
import users from './users'
import workshops from './workshops'

const admin = {
  ...users,
  ...questions,
  ...organisations,
  ...workshops,
  ...modules,
  ...emails,
}

export default admin
