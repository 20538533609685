/**
 * Currently, this component does not do much. It merely wraps the Chakra-UI
 * Text, without adding extra functionality, but, in future, can be set up
 * to make our own text component
 */

import { Text as ChakraText, TextProps } from '@chakra-ui/react'
import React from 'react'
import { variables } from 'styles'

type Props = TextProps & {
  children: string | React.ReactNode
  type?: 'link' | 'bold'
}

const Text: React.FC<Props> = ({ children, type, ...props }) => {
  const obj = {} as TextProps
  if (type === 'link') {
    obj['color'] = variables.colours.secondary.base
    obj['cursor'] = 'pointer'
    obj['fontWeight'] = 'bold'
    obj['_hover'] = {
      opacity: 0.75,
    }
  }
  if (type === 'bold') {
    obj['fontWeight'] = 'bold'
  }

  obj.fontFamily = 'Montserrat'

  return (
    <ChakraText lineHeight={'tall'} {...obj} {...props}>
      {children}
    </ChakraText>
  )
}

export default Text
