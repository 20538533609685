import { Box } from '@chakra-ui/react'
import { AdminTable, Modal, Text } from 'components'
import { useCallback, useState } from 'react'
import { client } from 'services'
import { QueryOptions, UserObj, WorkshopObj } from 'types'

interface Props {
  user: UserObj
  organisationId: string
  currentWorkshops: Set<string>
  handleWorkshopSelection: (workshop: WorkshopObj) => void
}

type WorkshopWithEnrollment = WorkshopObj & { disabled: boolean }

const WorkshopPopup: React.FC<Props> = ({
  user,
  organisationId,
  currentWorkshops,
  handleWorkshopSelection,
}) => {
  const [workshops, setWorkshops] = useState<WorkshopWithEnrollment[]>([])
  const [selectedWorkshop, setSelectedWorkshop] =
    useState<WorkshopWithEnrollment | null>(null)

  const fetchWorkshops = useCallback(
    async (options: QueryOptions & Props) => {
      const obj = organisationId ? { organisationId } : {}
      return await client.getWorkshops({
        ...options,
        ...obj,
        includePublic: true,
      })
    },
    [organisationId],
  )

  const setData = useCallback(
    (data: WorkshopObj[]) => {
      const mappedWorkshops = data.map((workshop) => ({
        ...workshop,

        // User is already enrolled in workshop, so it will be disabled
        disabled: currentWorkshops.has(workshop.id),
      }))
      setWorkshops(mappedWorkshops)
    },
    [currentWorkshops],
  )

  const confirmSelection = (workshop: WorkshopObj) => {
    handleWorkshopSelection(workshop)
    setSelectedWorkshop(null)
  }

  const handleRowClick = (index: number) => {
    setSelectedWorkshop(workshops[index])
  }

  return (
    <Box width={'100%'}>
      <AdminTable
        heading={'Add User to Workshop'}
        data={workshops}
        initialDataFetch={fetchWorkshops}
        type={'workshops'}
        onRowClick={handleRowClick}
        setParentData={setData}
        tableCaption={'Click on a row to add user to that workshop'}
      />

      <Modal
        isOpen={!!selectedWorkshop}
        title={'Add User to Workshop'}
        onClose={() => setSelectedWorkshop(null)}
        confirmText={'Add User'}
        handleSubmit={() => confirmSelection(selectedWorkshop as WorkshopObj)}
      >
        <Text>{`Are you sure you want to add ${user?.first_name} to the ${selectedWorkshop?.module_name}?`}</Text>
      </Modal>
    </Box>
  )
}

export default WorkshopPopup
