/**
 * Component to import users from a spreadsheet.
 * Worth noting that if we have a duplicate user, we will update their info with the spreadsheet data
 *
 * TODO: WRITE MORE COMMENTS
 * TODO: ENSURE YOU CANNOT IMPORT USERS FROM A DIFFERENT ORGANISATION
 */

import { Alert as ChakraAlert } from '@chakra-ui/react'
import { Modal } from 'components'
import { Button } from 'components'
import { Input } from 'components'
import { useOrganisation } from 'hooks'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { client } from 'services'
import { ImportUsersType, WorkshopObjWithUsersAndOrganisation } from 'types'
import { NewUserObj, UserSpreadsheetData } from 'types'
import { formatTextAsJSX } from 'utils/format'
import Xlsx from 'xlsx'

const SPREADSHEET_HEADING_MAPPING = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  organisation_id: 'Organisation',
} as any

interface ImportMessage {
  message: string
  type: 'success' | 'error' | 'warning'
}

interface UserImportProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: (data: ImportUsersType['created']) => void
  workshop?: WorkshopObjWithUsersAndOrganisation
}

const UserImport: React.FC<UserImportProps> = ({
  isOpen,
  onClose,
  onSuccess,
  workshop,
}) => {
  const { organisations } = useOrganisation()

  const [message, setMessage] = useState<ImportMessage | null>(null)
  const [file, setFile] = useState<File | null>(null)

  /**
   * We have the spreadsheet headers in human readable form, so we must convert
   * them to the format that the backend expects.
   * We also do a check for empty values and values that are too long. If we
   * find any issues, we skip that line, and report that to the user.
   */
  interface MissedLines {
    row: number
    missingRows: { column: string; reason: 'Missing' | 'Wrong Organisation' }[]
  }
  const sanitiseUserDataFromSpreadsheet = (
    data: UserSpreadsheetData[],
  ): { users: NewUserObj[]; missedLines: MissedLines[] } => {
    // Spreadsheet row that was skipped
    const missedLines = [] as MissedLines[]

    const userData = data
      .map((user) => {
        const organisationId = organisations.find(
          (o) =>
            o.name.toLocaleLowerCase() ===
            (user['Organisation'] || '').toLocaleLowerCase(),
        )?.id
        const organisationIdObj = {
          organisation_id: organisationId,
        }
        if (
          organisationId &&
          workshop?.organisation_id &&
          organisationId !== workshop?.organisation_id
        ) {
          missedLines.push({
            row: user['__rowNum__'],
            missingRows: [
              {
                column: 'Organisation',
                reason: 'Wrong Organisation',
              },
            ],
          })
        }

        const obj = {
          first_name: user['First Name'],
          last_name: user['Last Name'],
          email: user['Email'],
          role: 'user',
          row: user['__rowNum__'],
          ...organisationIdObj,
        }

        for (const [key, value] of Object.entries(obj)) {
          if (!value && value !== 0) {
            const index = missedLines.findIndex((line) => line.row === obj.row)
            index >= 0
              ? missedLines[index].missingRows.push({
                  column: SPREADSHEET_HEADING_MAPPING[key],
                  reason: 'Missing',
                })
              : missedLines.push({
                  row: obj.row,
                  missingRows: [
                    {
                      column: SPREADSHEET_HEADING_MAPPING[key],
                      reason: 'Missing',
                    },
                  ],
                })
          }
        }

        return obj
      })
      .filter((user) => user !== undefined) as NewUserObj[]

    return {
      users: userData,
      missedLines: missedLines,
    }
  }

  /**
   * TODO: WRTIE COMMENT
   */
  const handleImport = async () => {
    if (!file) return

    const reader = new FileReader()

    reader.onload = async (event) => {
      const data = event.target?.result
      const workbook = Xlsx.read(data, { type: 'binary' })
      const jsonData = Xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: [
            SPREADSHEET_HEADING_MAPPING.first_name,
            SPREADSHEET_HEADING_MAPPING.last_name,
            SPREADSHEET_HEADING_MAPPING.email,
            SPREADSHEET_HEADING_MAPPING.organisation_id,
          ]
        }
      ) as UserSpreadsheetData[]

      /**
       * Check to see if the first row of the spreadsheet is the headers.
       * If it is, remove them.
       */
      if (jsonData && jsonData.length) {
        const headers = jsonData[0]
        if (headers['First Name'] === 'First Name') jsonData.shift()
      }

      /**
       * Sanitise the spreadsheet data. If we are missing ANY fields, we will
       * notify the user, and not add any data from the spreadsheet.
       * We previously added the correct data, and skipped the corrupt data,
       * but it got way too confusing for the user.
       */
      const sanitisedUserData = sanitiseUserDataFromSpreadsheet(jsonData)
      if (sanitisedUserData.missedLines.length) {
        const message = `
          <b>IMPORT FAILED!!</b><br />
          Please add the missing data for the following
          <b>${sanitisedUserData.missedLines.length}</b> user${
          sanitisedUserData.missedLines.length === 1 ? '' : 's'
        } (<b>note:</b> if you have entered an organisation, but it is coming up as
          missing, make sure you add it to the organisation section first!):
        <ul>${sanitisedUserData.missedLines
          .map(
            (user) =>
              `<li>
                <b>Row ${user.row}:</b>
                <br />
                ${user.missingRows
                  .map(
                    (r) =>
                      `<ul>
                  ${
                    r.reason === 'Wrong Organisation'
                      ? `<ul><li><b>${r.reason}</b></li></ul>`
                      : `<ul><li>${r.reason} - <b>${r.column}</b></li></ul>`
                  }</ul>`,
                  )
                  .join('')}
              </li>`,
          )
          .join('')}</ul>`

        return setMessage({ type: 'error', message })
      }

      try {
        const response = await client.importUsers(
          sanitisedUserData.users,
          workshop?.id || '',
        )

        if ('error' in response) {
          return console.log('TODO: HANDLE ERROR: ', response)
        }

        // response.notCreated = [
        //   ...response.notCreated,
        //   ...sanitisedUserData.missedLines,
        // ]

        const message =
          response.notCreated.length === 0
            ? 'Successfully imported all users!'
            : `
            ${
              response.created.length
                ? `Successfully imported <b>${
                    response.created.length
                  }</b> users:
            <ul>${response.created
              .map((user) => `<li>${user.email}</li>`)
              .join('')}</ul><br />`
                : ''
            }
                Could not import the following <b>${
                  response.notCreated.length
                }</b> user${
                response.notCreated.length === 1 ? '' : 's'
              } from the spreadsheet:
            <ul>${response.notCreated
              .map((user) => `<li>${user.email} - ${user.error.message}</li>`)
              .join('')}</ul>`

        handleImportSuccess(response, message)
      } catch (error) {
        console.log('Users::handleImport() - Error: ', error)
        toast.error('Error importing users')
      }
    }

    reader.readAsBinaryString(file)
  }

  const handleImportSuccess = (data: ImportUsersType, message: string) => {
    const type = data.created.length
      ? data.notCreated.length
        ? 'warning'
        : 'success'
      : 'error'

    setMessage({ type, message })
    onSuccess(data.created)
  }

  return (
    <Modal
      title={'Import Users From Spreadsheet'}
      isOpen={isOpen}
      onClose={() => {
        setMessage(null)
        onClose()
      }}
      hideButtons={true}
    >
      {message ? (
        <ChakraAlert status={message.type}>
          {formatTextAsJSX(message.message)}
        </ChakraAlert>
      ) : (
        <>
          <Input
            id={'users-import'}
            type={'spreadsheet'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFile(e.target.files?.[0] || null)
            }
          />
          <Button
            isDisabled={!file}
            onClick={handleImport}
            float={'right'}
            colorScheme={'blue'}
          >
            {'Import'}
          </Button>
        </>
      )}
    </Modal>
  )
}

export default UserImport
