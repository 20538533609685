const variables = {
  colours: {
    primary: {
      base: '#C11333', // red
      dark: '#b40525',
    },
    secondary: {
      base: '#00A8B9', // cyan
      dark: '#01818F', // cyan
    },
    shadow: '#0000004d',
    background: {
      content: '#eaeaea',
    },
    font: {
      hover: '#c6b6b6',
      link: '#1877f2',
      white: '#ffffff',
      black: '#000000',
      grey: '##535353',
    },
    orange: '#f7a221',
    green: '#38A169',
  },

  spacing: {
    app: 3, // padding added to App Wrapper
  },
}

export default variables
