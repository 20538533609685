/**
 * A file that holds common Form Control Functions
 */

import { ButtonProps, Flex, VStack } from '@chakra-ui/react'
import { Button, Input, Radio, Select } from 'components'
import { InputProps } from 'components/UI/Input/Input'
import { useAlert } from 'hooks'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  FetchResponse,
  NewOrganisationObj,
  NewQuestionObj,
  NewWorkshopObj,
  OrganisationObj,
  RadioOptionsObj,
  SelectOptionsObj,
  UserObj,
  WorkshopObj,
} from 'types'
import { isValidEmail, isValidPassword } from 'utils/validators'

// Types

interface IndexableObject {
  [key: string]: string
}

type FormData = IndexableObject & { id: string } & (
    | NewOrganisationObj
    | NewWorkshopObj
    | UserObj
    | NewQuestionObj
    | any
  ) // TODO: REMOVE ANY!!!

interface Props {
  onSubmit: (data: FormData) => void
  fields: InputProps[]
  apiDeleteFunction?: (id: string) => Promise<FetchResponse<{}>>
  onSuccessfulDelete?: (id: string) => void
  children?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  data?: OrganisationObj | WorkshopObj | UserObj | NewQuestionObj | null
  editing?: boolean
  selectOptions?: SelectOptionsObj
  radioOptions?: { [key: string]: RadioOptionsObj }
  submitButtonProps?: { text: string } & ButtonProps
  wasInvalidSubmission?: boolean
  setWasInvalidSubmission?: Dispatch<SetStateAction<boolean>>
  optionalFields?: string[]
  isSubmitting?: boolean
  onChange?: (name: string, value: string) => void
}

const FormWrapper: React.FC<Props> = ({
  apiDeleteFunction,
  onSuccessfulDelete,
  onSubmit,
  fields,
  children,
  data,
  editing,
  selectOptions = {},
  radioOptions = {},
  submitButtonProps = { text: '' },
  wasInvalidSubmission,
  setWasInvalidSubmission,
  optionalFields,
  isSubmitting,
  onChange,
}) => {
  const { onClose, onOpen } = useAlert()
  const { text: submitButtonText, ...submitButtonRest } = submitButtonProps

  /* Form States */

  const initialFormValues: FormData = fields.reduce(
    (acc, f) => (f.id ? { ...acc, [f.id]: '' } : acc),
    {},
  )

  const [formValues, setFormValues] = useState<FormData>(
    data || initialFormValues,
  )
  const [formErrors, setFormErrors] = useState<FormData>(initialFormValues)
  const [formFields, setFormFields] =
    useState<(InputProps & { hidden?: boolean })[]>(fields)
  const [isLocallySubmitting, setIsLocallySubmitting] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [hasBeenChanged, setHasBeenChanged] = useState(false)

  // const [optionalFields, setOptionalFields] = useState<(string | undefined)[]>(
  //   [],
  // )

  // /* Lifecylce */
  // useEffect(() => {
  //   const optional = fields.filter(
  //     (f) => f.formControlProps?.isRequired === false,
  //   )
  //   if (optional && optional.length) {
  //     setOptionalFields(optional.map((f) => f.id))
  //   }
  // }, [fields])

  /* Validation */

  const validateFormValues = (values: IndexableObject) => {
    const errors = {} as FormData

    Object.keys(values).forEach((key) => {
      if (key === 'email') {
        errors[key] = isValidEmail(values[key])
          ? ''
          : 'Please enter a valid email'
      } else if (key === 'passwordConfirm') {
        errors[key] =
          values[key] === formValues.password ? '' : 'Passwords do not match'
      } else if (key === 'password') {
        errors[key] = isValidPassword(values[key])
          ? ''
          : 'Must be at least 8 characters'
      } else if (key === 'postcode') {
        errors[key] = /[0-9]{4}/.test(values[key])
          ? ''
          : 'Please enter a valid postcode'
      } else if (optionalFields?.includes(key)) {
        errors[key] = ''
      } else {
        errors[key] = values[key] ? '' : 'This field is required'
      }
    })

    return errors
  }

  // const isButtonDisabled =
  //   Object.entries(formValues).some(
  //     ([key, value]) =>
  //       !value &&
  //       !formFields.find((f) => f.id === key)?.hidden &&
  //       !optionalFields?.includes(key),
  //   ) ||
  //   Object.entries(formErrors).some(
  //     ([k, v]) => v && !formFields.find((f) => f.id === k)?.hidden,
  //   )

  // const updateButton = () => {
  //   const isButtonDisabled =
  //   Object.entries(formValues).some(
  //     ([key, value]) =>
  //       !value &&
  //       !formFields.find((f) => f.id === key)?.hidden &&
  //       !optionalFields?.includes(key),
  //   ) ||
  //   Object.entries(formErrors).some(
  //     ([k, v]) => v && !formFields.find((f) => f.id === k)?.hidden,
  //   )
  // }

  useEffect(() => {
    const missingValue = Object.entries(formValues).some(
      ([key, value]) =>
        !value &&
        !formFields.find((f) => f.id === key)?.hidden &&
        !optionalFields?.includes(key),
    )
    const hasError = Object.entries(formErrors).some(
      ([k, v]) => v && !formFields.find((f) => f.id === k)?.hidden,
    )

    setIsButtonDisabled(missingValue || hasError)
  }, [formValues, formErrors, formFields, optionalFields])

  const hideFieldsOnRadioValue = (obj: RadioOptionsObj, value: any) => {
    if (obj.hideOtherFields) {
      for (const field of obj.hideOtherFields) {
        if (field.onValue === value) {
          setFormFields((prev) =>
            prev.map((f) =>
              f.id === field.field ? { ...f, hidden: true, value: '' } : f,
            ),
          )
        } else {
          setFormFields((prev) =>
            prev.map((f) =>
              f.id === field.field ? { ...f, hidden: false } : f,
            ),
          )
        }
      }
    }
  }

  // Hide fields on initial load
  useEffect(() => {
    for (const f of Object.keys(radioOptions)) {
      const castData = data as IndexableObject
      const value = castData ? castData[f] : ''
      hideFieldsOnRadioValue(radioOptions[f], value)
    }
  }, [radioOptions, data])

  /* Handler functions */

  const handleRadioChange = (obj: { value: string; id: string }) => {
    setHasBeenChanged(true)
    hideFieldsOnRadioValue(radioOptions[obj.id], obj.value)

    setFormValues({
      ...formValues,
      [obj.id]: obj.value,
    })

    setFormErrors({
      ...formErrors,
      ...validateFormValues({ [obj.id]: obj.value }),
    })

    setWasInvalidSubmission && setWasInvalidSubmission(false)
  }

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    setHasBeenChanged(true)

    const { name, value, type } = event.target

    // Implement maxLength for <Input type="number" />
    let validatedValue
    if (type === 'number') {
      validatedValue = value.replaceAll('e', '')
      const { maxLength } = event.target as HTMLInputElement
      if (maxLength && maxLength > 0 && value.length > maxLength) {
        validatedValue = validatedValue.slice(0, maxLength)
      }
    }

    // Update Errors
    setFormErrors({
      ...formErrors,
      ...validateFormValues({ [name]: validatedValue || value }),
    })

    // Update Form Values
    let formValuesObj = {
      ...formValues,
      [name]: validatedValue || value,
    }

    if (type.includes('select')) {
      const option = selectOptions[name].find((o) => o.id === value)

      if (option && option.handleChange) {
        const newState = option.handleChange(value, formValuesObj)

        if (newState) {
          formValuesObj = newState
        }
      }
    }

    setFormValues(formValuesObj)

    setWasInvalidSubmission && setWasInvalidSubmission(false)
    onChange && onChange(name, value)
  }

  const handleBlur = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = event.target

    setFormErrors({
      ...formErrors,
      ...validateFormValues({ [name]: value }),
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Filter out any hidden fields
    const hiddenFields = new Set(
      formFields.filter((f) => f.hidden).map((f) => f.id),
    ) as Set<string>

    const filteredFormValues = {} as any
    for (const [k, v] of Object.entries(formValues)) {
      if (!hiddenFields.has(k)) {
        filteredFormValues[k] = v || null
      }
    }

    const errors = validateFormValues(filteredFormValues)
    if (Object.values(errors).some((value) => value)) {
      return toast.error('Please fill in all the fields!')
    }

    onSubmit(filteredFormValues)
  }

  const onDeleteConfirm = async () => {
    setIsLocallySubmitting(true)
    onClose()

    if (!apiDeleteFunction) return
    if (!data || !('id' in data) || !data.id) return

    try {
      const response = await apiDeleteFunction(data.id)

      if ('error' in response) {
        throw response
      }

      toast.success('Delete successful!')
      onSuccessfulDelete && onSuccessfulDelete(data.id)
    } catch (error: any) {
      toast.error(
        error?.message || 'Something went wrong! Please try again later.',
      )
    } finally {
      setIsLocallySubmitting(false)
    }
  }

  const defaultSubmitButtonProps =
    editing === undefined ? {} : { colorScheme: editing ? 'blue' : 'green' }

  const defaultFieldProps = {
    onChange: handleChange,
    onBlur: handleBlur,
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <VStack spacing={'4'}>
          {formFields.map((field: any, index: number) => {
            if (!field.id || field.hidden) return null

            if (field.type === 'select') {
              return (
                <Select
                  key={`${field.id}${index}`}
                  value={formValues[field.id]}
                  placeholder={field.placeholder || 'Select an option'}
                  formControlProps={{ isInvalid: !!formErrors.date }}
                  errorMessage={formErrors.date}
                  {...defaultFieldProps}
                  {...field}
                >
                  {selectOptions[field.id].map((org, index) => (
                    <option key={`${org.id}${index}${org.name}`} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </Select>
              )
            }

            if (field.type === 'radio') {
              return (
                <Radio
                  key={`${field.id}${index}`}
                  value={formValues[field.id]}
                  options={radioOptions[field.id].options}
                  {...defaultFieldProps}
                  {...field}
                  onChange={(value) =>
                    handleRadioChange({
                      value,
                      id: field.id,
                    })
                  }
                />
              )
            }

            return (
              <Input
                key={`${field.id}${index}`}
                value={formValues[field.id]}
                formControlProps={{ isInvalid: !!formErrors[field.id] }}
                errorMessage={formErrors[field.id]}
                {...defaultFieldProps}
                {...field}
              />
            )
          })}
          {children}
        </VStack>

        <Flex mt={'3'} justifyContent={'flex-end'}>
          {editing ? (
            <Button
              onClick={() => onOpen(onDeleteConfirm)}
              mr={'2'}
              mt={'1rem'}
              colorScheme={'red'}
            >
              {'Delete'}
            </Button>
          ) : null}

          <Button
            type={'submit'}
            isDisabled={
              isLocallySubmitting ||
              isSubmitting ||
              isButtonDisabled ||
              wasInvalidSubmission ||
              !hasBeenChanged
            }
            mt={'1rem'}
            {...defaultSubmitButtonProps}
            {...submitButtonRest}
          >
            {submitButtonText ||
              (editing
                ? isSubmitting || isLocallySubmitting
                  ? 'Updating ...'
                  : 'Update'
                : isSubmitting || isLocallySubmitting
                ? 'Creating ...'
                : 'Create')}
          </Button>
        </Flex>
      </form>
    </>
  )
}

export default FormWrapper
