import { InputProps } from 'components/UI/Input/Input'

const fields = [
  {
    id: 'question',
    label: 'Question',
    type: 'textarea',
    placeholder: 'What is your question?',
  },
] as InputProps[]

export default fields
