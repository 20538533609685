import { useDisclosure } from '@chakra-ui/react'
import { AdminTable, Modal } from 'components'
import { QuestionForm } from 'components'
import { useOrganisation } from 'hooks'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { client } from 'services'
import { QueryOptions, QuestionObj } from 'types'

const ReflectionQuestions: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const params = useParams<{ module_id: string }>()
  const moduleId = params.module_id as string
  const { modules } = useOrganisation()

  const [questions, setQuestions] = useState<QuestionObj[]>([])
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionObj | null>(
    null,
  )

  const fetchData = useCallback(
    async (options: QueryOptions) => {
      return await client.getQuestions({
        ...options,
        module_id: moduleId,
      })
    },
    [moduleId],
  )

  const onSuccessfulCreate = async (data: QuestionObj) => {
    const questionAsArr = questions || []

    const index = questionAsArr.findIndex((q) => q.id === data.id)
    if (index >= 0) {
      const orderIndex = questionAsArr.findIndex(
        (q) => q.question_order === data.question_order,
      )

      // If the question already exists, update it
      setQuestions((prev) =>
        (prev || [])
          .map((q, i) => {
            if (q.id === data.id) return data
            if (i === orderIndex)
              return {
                ...q,
                question_order: (questions || [])[index].question_order,
              }
            return q
          })
          .sort((a, b) => (a.question_order > b.question_order ? 1 : -1)),
      )
    } else {
      // If the question does not exist, add it to the end of the array
      setQuestions([...questionAsArr, data])
    }

    setSelectedQuestion(null)
    onClose()
  }

  const onSuccessfulDelete = async (id: string) => {
    const filteredQuestions = (questions || []).filter(
      (question) => question.id !== id,
    )
    setQuestions([...filteredQuestions])
    setSelectedQuestion(null)
    onClose()
  }

  const handleRowClick = (index: number) => {
    setSelectedQuestion(questions[index])
    onOpen()
  }

  return (
    <>
      <AdminTable
        heading={`${
          modules.find((m) => m.id === moduleId)?.name || ''
        } - Reflection Questions`}
        data={questions}
        initialDataFetch={fetchData}
        type={'questions'}
        onRowClick={handleRowClick}
        onButtonClick={onOpen}
        setParentData={setQuestions}
        defaultSearchBy={'question'}
      />
      <Modal
        title={!!selectedQuestion ? 'Question' : 'Create New Question'}
        isOpen={isOpen}
        onClose={() => {
          setSelectedQuestion(null)
          onClose()
        }}
        hideButtons={true}
      >
        <QuestionForm
          moduleId={moduleId}
          onSuccessfulCreate={onSuccessfulCreate}
          onSuccessfulDelete={onSuccessfulDelete}
          question={selectedQuestion}
          editing={!!selectedQuestion}
        />
      </Modal>
    </>
  )
}

export default ReflectionQuestions
